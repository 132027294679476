import { Table } from 'antd';
import { useState } from 'react';
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Plan',
        dataIndex: 'plan',
        key: 'plan',
    },
    {
        title: "User's plan",
        dataIndex: 'image1',
        key: 'image1',
        render: (image1) => {
            return (
                <img src={image1} width={'40px'} height={'40px'}></img>
            )
        }
    },
    {
        title: 'our plan',
        dataIndex: 'image2',
        key: 'image2',
        render: (image2) => {
            return (
                <img src={image2} width={'40px'} height={'40px'}></img>
            )
        }
    }

];
const data = [
    {
        key: 1,
        name: 'John Brown',
        date: '06/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
        image1: 'image1.png',
        image2: 'image2.png'
    },
    {
        key: 2,
        name: 'Jim Green',
        date: '06/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
        image1: 'image1.png',
        image2: 'image2.png'
    },
    {
        key: 3,
        name: 'Toms',
        date: '06/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        description: 'My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.',
        image1: 'image1.png',
        image2: 'image2.png'
    },
    {
        key: 4,
        name: 'Joe Black',
        date: '06/06/2023',
        plan: 'Asset: EURUSD, Buy: 1.09232, Target: 1.094232, Stop: 1.091233',
        description: 'My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.',
        image1: 'image1.png',
        image2: 'image2.png'
    },
];
const History = () => (
    <div className="container mx-auto pt-8 pb-16 px-4">
        <Table
            columns={columns}
            expandable={{
                expandedRowRender: (record) => (
                    <div>
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.description}
                        </p>
                        <div style={{ display: "flex" }}>
                            <img style={{ padding: "10px" }} src={record.image1} width={600} height={600} />
                            <img style={{ padding: "10px" }} src={record.image2} width={600} height={600} />
                        </div>
                    </div>
                ),
                rowExpandable: (record) => record.name !== 'Not1 Expandable',
            }}
            dataSource={data}
        />
    </div>
);
export default History;