import React, { useEffect, useState } from "react";
import axios from "axios";
import Financial from '../../components/Chart';
import { PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Segmented,
    Row,
    Form,
    Input,
    Typography,
    Radio,
    Select,
    Collapse,
    Switch,
    TreeSelect,
    Label,
} from 'antd';
import { BASE_URL } from "../../config";
const { Title } = Typography;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const itemsNest = [
    {
        key: '1',
        label: 'Pattern',
        children: <p>{text}</p>,
    },
];

const items = [
    {
        key: '1',
        label: 'Pattern',
        children: <>
            <div style={{ paddingTop: '5px' }}>
                <Button>Bullish Double Top</Button>
            </div>
            <div style={{ paddingTop: '5px' }}>
                <Button>Bullish Double Top</Button>
            </div>
            <div style={{ paddingTop: '5px' }}>
                <Button>Bullish Double Top</Button>
            </div>
            <div style={{ paddingTop: '5px' }}>
                <Button>Bullish Double Top</Button>
            </div>
        </>,
    },
    {
        key: '2',
        label: 'Technical Indicator',
        children: <>
        <div style={{ paddingTop: '5px' }}>
            <Button>Moving Average</Button>
        </div>
        <div style={{ paddingTop: '5px' }}>
            <Button>Moving Average</Button>
        </div>
    </>,
    }
];
function Dashboard() {
    return (
        <div className="container mx-auto pt-8 pb-16 px-4">
            <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-3/4 px-2 py-8" style={{ border: 'solid', borderColor: 'whitesmoke', borderRadius: '20px' }}>
                    <Row>
                        <Col offset={1} span={6}>
                            <Title level={3}>EURUSD: {1.09273}</Title>
                        </Col>
                        <Col offset={1} span={6}>
                            <Title level={3}>Signal: {`Buy`}</Title>
                        </Col>
                    </Row>
                    <Segmented options={['15M', '1H', '4H', '1D', '1M']} />
                    <Financial />
                </div>

                <div className="hidden lg:block border-l border-gray-300 h-full my-8"></div>

                <div className="w-full lg:w-1/4 px-2 py-8" style={{ paddingLeft: '50px' }}>
                    <Collapse defaultActiveKey={['1','2']} items={items} />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;