let oginitialData = [
    {
      date: "2021-10-20 13:45:00",
      open: 184.6281,
      low: 184.6281,
      high: 184.7,
      close: 184.7,
      volume: 1499
    },
    {
      date: "2021-10-20 13:44:00",
      open: 184.57,
      low: 184.57,
      high: 184.6576,
      close: 184.64,
      volume: 1628
    },
    {
      date: "2021-10-20 13:43:00",
      open: 184.55,
      low: 184.43,
      high: 184.6799,
      close: 184.432,
      volume: 1835
    },
    {
      date: "2021-10-20 13:42:00",
      open: 184.86,
      low: 184.7,
      high: 184.86,
      close: 184.7,
      volume: 1998
    },
    {
      date: "2021-10-20 13:41:00",
      open: 184.8466,
      low: 184.8466,
      high: 184.8466,
      close: 184.8466,
      volume: 461
    },
    {
      date: "2021-10-20 13:40:00",
      open: 184.7599,
      low: 184.7599,
      high: 184.86,
      close: 184.7723,
      volume: 851
    },
    {
      date: "2021-10-20 13:39:00",
      open: 184.99,
      low: 184.8001,
      high: 184.99,
      close: 184.81,
      volume: 1709
    },
    {
      date: "2021-10-20 13:38:00",
      open: 185.08,
      low: 185.08,
      high: 185.08,
      close: 185.08,
      volume: 421
    },
    {
      date: "2021-10-20 13:37:00",
      open: 184.871,
      low: 184.8703,
      high: 184.9,
      close: 184.8703,
      volume: 1190
    },
    {
      date: "2021-10-20 13:36:00",
      open: 184.88,
      low: 184.88,
      high: 184.98,
      close: 184.98,
      volume: 727
    },
    {
      date: "2021-10-20 13:35:00",
      open: 184.675,
      low: 184.5801,
      high: 184.82,
      close: 184.79,
      volume: 1640
    },
    {
      date: "2021-10-20 13:34:00",
      open: 184.52,
      low: 184.52,
      high: 184.71,
      close: 184.64,
      volume: 1420
    },
    {
      date: "2021-10-20 13:33:00",
      open: 184.522,
      low: 184.522,
      high: 184.53,
      close: 184.53,
      volume: 1099
    },
    {
      date: "2021-10-20 13:32:00",
      open: 184.75,
      low: 184.7,
      high: 184.76,
      close: 184.7,
      volume: 808
    },
    {
      date: "2021-10-20 13:31:00",
      open: 184.73,
      low: 184.5551,
      high: 184.73,
      close: 184.5551,
      volume: 1361
    },
    {
      date: "2021-10-20 13:30:00",
      open: 184.8291,
      low: 184.8291,
      high: 185.03,
      close: 185.03,
      volume: 1884
    },
    {
      date: "2021-10-20 13:29:00",
      open: 185.05,
      low: 184.897,
      high: 185.05,
      close: 184.897,
      volume: 2827
    },
    {
      date: "2021-10-20 13:28:00",
      open: 184.9869,
      low: 184.9225,
      high: 184.9869,
      close: 184.9225,
      volume: 539
    },
    {
      date: "2021-10-20 13:27:00",
      open: 185.15,
      low: 185.0,
      high: 185.15,
      close: 185.0,
      volume: 420
    },
    {
      date: "2021-10-20 13:26:00",
      open: 185.09,
      low: 185.09,
      high: 185.36,
      close: 185.2,
      volume: 4134
    },
    {
      date: "2021-10-20 13:25:00",
      open: 184.8716,
      low: 184.8716,
      high: 185.09,
      close: 185.0315,
      volume: 2183
    },
    {
      date: "2021-10-20 13:24:00",
      open: 184.9945,
      low: 184.85,
      high: 185.0,
      close: 184.85,
      volume: 1007
    },
    {
      date: "2021-10-20 13:23:00",
      open: 184.6824,
      low: 184.6824,
      high: 184.88,
      close: 184.79,
      volume: 1212
    },
    {
      date: "2021-10-20 13:22:00",
      open: 184.78,
      low: 184.78,
      high: 184.92,
      close: 184.92,
      volume: 1220
    },
    {
      date: "2021-10-20 13:21:00",
      open: 184.6,
      low: 184.5,
      high: 184.65,
      close: 184.6,
      volume: 4350
    },
    {
      date: "2021-10-20 13:20:00",
      open: 184.98,
      low: 184.5229,
      high: 184.98,
      close: 184.57,
      volume: 8607
    },
    {
      date: "2021-10-20 13:19:00",
      open: 185.19,
      low: 184.9208,
      high: 185.19,
      close: 184.9208,
      volume: 6480
    },
    {
      date: "2021-10-20 13:18:00",
      open: 185.2,
      low: 185.18,
      high: 185.2,
      close: 185.2,
      volume: 1768
    },
    {
      date: "2021-10-20 13:16:00",
      open: 185.5,
      low: 185.45,
      high: 185.5,
      close: 185.45,
      volume: 2886
    },
    {
      date: "2021-10-20 13:14:00",
      open: 185.6499,
      low: 185.34,
      high: 185.65,
      close: 185.635,
      volume: 2970
    },
    {
      date: "2021-10-20 13:13:00",
      open: 185.9,
      low: 185.65,
      high: 185.9,
      close: 185.65,
      volume: 3122
    },
    {
      date: "2021-10-20 13:12:00",
      open: 186.16,
      low: 186.16,
      high: 186.16,
      close: 186.16,
      volume: 578
    },
    {
      date: "2021-10-20 13:11:00",
      open: 185.93,
      low: 185.93,
      high: 185.93,
      close: 185.93,
      volume: 911
    },
    {
      date: "2021-10-20 13:10:00",
      open: 185.83,
      low: 185.775,
      high: 185.83,
      close: 185.775,
      volume: 708
    },
    {
      date: "2021-10-20 13:09:00",
      open: 185.66,
      low: 185.66,
      high: 185.66,
      close: 185.66,
      volume: 315
    },
    {
      date: "2021-10-20 13:08:00",
      open: 185.7856,
      low: 185.75,
      high: 185.7856,
      close: 185.75,
      volume: 1468
    },
    {
      date: "2021-10-20 13:07:00",
      open: 185.7846,
      low: 185.7846,
      high: 185.7846,
      close: 185.7846,
      volume: 341
    },
    {
      date: "2021-10-20 13:06:00",
      open: 185.83,
      low: 185.83,
      high: 185.85,
      close: 185.85,
      volume: 649
    },
    {
      date: "2021-10-20 13:05:00",
      open: 185.77,
      low: 185.65,
      high: 185.77,
      close: 185.68,
      volume: 1108
    },
    {
      date: "2021-10-20 13:03:00",
      open: 186.06,
      low: 186.0275,
      high: 186.13,
      close: 186.095,
      volume: 997
    },
    {
      date: "2021-10-20 13:02:00",
      open: 186.0,
      low: 185.82,
      high: 186.02,
      close: 185.8301,
      volume: 2774
    },
    {
      date: "2021-10-20 13:00:00",
      open: 186.57,
      low: 186.3,
      high: 186.57,
      close: 186.3,
      volume: 1060
    },
    {
      date: "2021-10-20 12:59:00",
      open: 186.44,
      low: 186.44,
      high: 186.44,
      close: 186.44,
      volume: 500
    },
    {
      date: "2021-10-20 12:58:00",
      open: 186.5,
      low: 186.5,
      high: 186.5,
      close: 186.5,
      volume: 504
    },
    {
      date: "2021-10-20 12:55:00",
      open: 186.54,
      low: 186.36,
      high: 186.57,
      close: 186.36,
      volume: 667
    },
    {
      date: "2021-10-20 12:52:00",
      open: 186.2338,
      low: 186.2338,
      high: 186.2338,
      close: 186.2338,
      volume: 633
    },
    {
      date: "2021-10-20 12:51:00",
      open: 186.35,
      low: 186.35,
      high: 186.35,
      close: 186.35,
      volume: 628
    },
    {
      date: "2021-10-20 12:50:00",
      open: 186.35,
      low: 186.07,
      high: 186.351,
      close: 186.2294,
      volume: 11027
    },
    {
      date: "2021-10-20 12:49:00",
      open: 186.58,
      low: 186.29,
      high: 186.72,
      close: 186.35,
      volume: 19322
    },
    {
      date: "2021-10-20 12:48:00",
      open: 186.47,
      low: 186.47,
      high: 186.47,
      close: 186.47,
      volume: 894
    },
    {
      date: "2021-10-20 12:47:00",
      open: 186.57,
      low: 186.57,
      high: 186.57,
      close: 186.57,
      volume: 440
    },
    {
      date: "2021-10-20 12:45:00",
      open: 186.72,
      low: 186.35,
      high: 186.72,
      close: 186.35,
      volume: 20506
    },
    {
      date: "2021-10-20 12:44:00",
      open: 187.11,
      low: 186.77,
      high: 187.11,
      close: 186.944,
      volume: 1724
    },
    {
      date: "2021-10-20 12:43:00",
      open: 187.33,
      low: 186.98,
      high: 187.33,
      close: 186.99,
      volume: 2029
    },
    {
      date: "2021-10-20 12:42:00",
      open: 187.37,
      low: 187.25,
      high: 187.37,
      close: 187.25,
      volume: 1876
    },
    {
      date: "2021-10-20 12:41:00",
      open: 186.73,
      low: 186.73,
      high: 187.55,
      close: 187.48,
      volume: 6548
    },
    {
      date: "2021-10-20 12:40:00",
      open: 187.6781,
      low: 186.73,
      high: 187.9716,
      close: 186.738,
      volume: 34523
    },
    {
      date: "2021-10-20 12:39:00",
      open: 187.6982,
      low: 187.6609,
      high: 188.0,
      close: 187.6609,
      volume: 4507
    },
    {
      date: "2021-10-20 12:38:00",
      open: 187.8653,
      low: 187.5643,
      high: 188.0,
      close: 187.78,
      volume: 7019
    },
    {
      date: "2021-10-20 12:37:00",
      open: 186.8,
      low: 186.8,
      high: 187.65,
      close: 187.65,
      volume: 7147
    },
    {
      date: "2021-10-20 12:36:00",
      open: 186.929,
      low: 186.79,
      high: 187.12,
      close: 187.105,
      volume: 14235
    },
    {
      date: "2021-10-20 12:35:00",
      open: 186.56,
      low: 186.3606,
      high: 187.05,
      close: 186.95,
      volume: 21875
    },
    {
      date: "2021-10-20 12:34:00",
      open: 186.749,
      low: 186.52,
      high: 186.77,
      close: 186.77,
      volume: 6720
    },
    {
      date: "2021-10-20 12:33:00",
      open: 186.7,
      low: 186.38,
      high: 186.91,
      close: 186.7,
      volume: 34921
    },
    {
      date: "2021-10-20 12:32:00",
      open: 186.43,
      low: 186.3853,
      high: 186.72,
      close: 186.5574,
      volume: 12460
    },
    {
      date: "2021-10-20 12:31:00",
      open: 186.5566,
      low: 186.32,
      high: 186.72,
      close: 186.5494,
      volume: 10714
    },
    {
      date: "2021-10-20 12:30:00",
      open: 186.53,
      low: 186.46,
      high: 186.72,
      close: 186.46,
      volume: 7189
    },
    {
      date: "2021-10-20 12:29:00",
      open: 185.54,
      low: 185.53,
      high: 186.8,
      close: 186.72,
      volume: 30891
    },
    {
      date: "2021-10-20 12:28:00",
      open: 185.38,
      low: 185.3,
      high: 185.6045,
      close: 185.6045,
      volume: 5257
    },
    {
      date: "2021-10-20 12:27:00",
      open: 185.37,
      low: 185.3,
      high: 185.38,
      close: 185.3,
      volume: 806
    },
    {
      date: "2021-10-20 12:26:00",
      open: 185.1874,
      low: 184.96,
      high: 185.2,
      close: 185.2,
      volume: 6435
    },
    {
      date: "2021-10-20 12:19:00",
      open: 185.13,
      low: 185.11,
      high: 185.28,
      close: 185.14,
      volume: 7585
    },
    {
      date: "2021-10-20 12:18:00",
      open: 185.13,
      low: 185.13,
      high: 185.13,
      close: 185.13,
      volume: 456
    },
    {
      date: "2021-10-20 12:17:00",
      open: 185.35,
      low: 185.35,
      high: 185.35,
      close: 185.35,
      volume: 455
    },
    {
      date: "2021-10-20 12:16:00",
      open: 185.31,
      low: 185.28,
      high: 185.31,
      close: 185.29,
      volume: 864
    },
    {
      date: "2021-10-20 12:15:00",
      open: 185.43,
      low: 185.34,
      high: 185.43,
      close: 185.34,
      volume: 778
    },
    {
      date: "2021-10-20 12:14:00",
      open: 185.44,
      low: 185.44,
      high: 185.44,
      close: 185.44,
      volume: 272
    },
    {
      date: "2021-10-20 12:13:00",
      open: 185.446,
      low: 185.43,
      high: 185.446,
      close: 185.43,
      volume: 569
    },
    {
      date: "2021-10-20 12:12:00",
      open: 185.51,
      low: 185.51,
      high: 185.51,
      close: 185.51,
      volume: 632
    },
    {
      date: "2021-10-20 12:11:00",
      open: 185.6285,
      low: 185.515,
      high: 185.6285,
      close: 185.515,
      volume: 455
    },
    {
      date: "2021-10-20 12:10:00",
      open: 185.0,
      low: 185.0,
      high: 185.41,
      close: 185.41,
      volume: 4682
    },
    {
      date: "2021-10-20 12:09:00",
      open: 185.0,
      low: 184.981,
      high: 185.09,
      close: 185.09,
      volume: 1622
    },
    {
      date: "2021-10-20 12:08:00",
      open: 184.92,
      low: 184.92,
      high: 185.12,
      close: 184.98,
      volume: 1216
    },
    {
      date: "2021-10-20 12:07:00",
      open: 185.17,
      low: 184.86,
      high: 185.17,
      close: 184.86,
      volume: 1659
    },
    {
      date: "2021-10-20 12:06:00",
      open: 185.08,
      low: 185.08,
      high: 185.08,
      close: 185.08,
      volume: 757
    },
    {
      date: "2021-10-20 12:05:00",
      open: 185.07,
      low: 185.07,
      high: 185.07,
      close: 185.07,
      volume: 734
    },
    {
      date: "2021-10-20 12:03:00",
      open: 184.95,
      low: 184.95,
      high: 184.95,
      close: 184.95,
      volume: 719
    },
    {
      date: "2021-10-20 11:59:00",
      open: 185.19,
      low: 185.09,
      high: 185.19,
      close: 185.15,
      volume: 2119
    },
    {
      date: "2021-10-20 11:58:00",
      open: 185.47,
      low: 185.23,
      high: 185.49,
      close: 185.23,
      volume: 1311
    },
    {
      date: "2021-10-20 11:57:00",
      open: 185.77,
      low: 185.77,
      high: 185.77,
      close: 185.77,
      volume: 924
    },
    {
      date: "2021-10-20 11:56:00",
      open: 185.69,
      low: 185.65,
      high: 185.81,
      close: 185.81,
      volume: 543
    },
    {
      date: "2021-10-20 11:55:00",
      open: 185.69,
      low: 185.64,
      high: 185.81,
      close: 185.64,
      volume: 1328
    },
    {
      date: "2021-10-20 11:54:00",
      open: 185.4616,
      low: 185.4616,
      high: 185.6,
      close: 185.6,
      volume: 536
    },
    {
      date: "2021-10-20 11:53:00",
      open: 185.54,
      low: 185.54,
      high: 185.54,
      close: 185.54,
      volume: 718
    },
    {
      date: "2021-10-20 11:52:00",
      open: 185.5,
      low: 185.5,
      high: 185.5,
      close: 185.5,
      volume: 588
    },
    {
      date: "2021-10-20 11:51:00",
      open: 185.3393,
      low: 185.3393,
      high: 185.68,
      close: 185.68,
      volume: 989
    },
    {
      date: "2021-10-20 11:50:00",
      open: 185.4523,
      low: 185.45,
      high: 185.59,
      close: 185.59,
      volume: 1224
    },
    {
      date: "2021-10-20 11:49:00",
      open: 185.25,
      low: 185.25,
      high: 185.25,
      close: 185.25,
      volume: 312
    },
    {
      date: "2021-10-20 11:48:00",
      open: 185.37,
      low: 185.19,
      high: 185.37,
      close: 185.19,
      volume: 484
    },
    {
      date: "2021-10-20 11:47:00",
      open: 185.2537,
      low: 184.9401,
      high: 185.27,
      close: 185.27,
      volume: 1504
    },
    {
      date: "2021-10-20 11:46:00",
      open: 184.9709,
      low: 184.94,
      high: 185.2573,
      close: 185.2328,
      volume: 1657
    },
    {
      date: "2021-10-20 11:45:00",
      open: 185.3,
      low: 184.89,
      high: 185.3499,
      close: 184.89,
      volume: 7210
    },
    {
      date: "2021-10-20 11:44:00",
      open: 185.565,
      low: 185.44,
      high: 185.664,
      close: 185.44,
      volume: 844
    },
    {
      date: "2021-10-20 11:43:00",
      open: 185.81,
      low: 185.6,
      high: 185.81,
      close: 185.6,
      volume: 1126
    },
    {
      date: "2021-10-20 11:42:00",
      open: 185.69,
      low: 185.69,
      high: 185.74,
      close: 185.7,
      volume: 647
    },
    {
      date: "2021-10-20 11:41:00",
      open: 185.6231,
      low: 185.42,
      high: 185.6231,
      close: 185.55,
      volume: 2708
    },
    {
      date: "2021-10-20 11:40:00",
      open: 186.15,
      low: 185.92,
      high: 186.15,
      close: 185.92,
      volume: 1026
    },
    {
      date: "2021-10-20 11:39:00",
      open: 186.37,
      low: 186.3,
      high: 186.37,
      close: 186.31,
      volume: 1320
    },
    {
      date: "2021-10-20 11:37:00",
      open: 186.23,
      low: 186.23,
      high: 186.23,
      close: 186.23,
      volume: 319
    },
    {
      date: "2021-10-20 11:36:00",
      open: 186.11,
      low: 186.11,
      high: 186.11,
      close: 186.11,
      volume: 565
    },
    {
      date: "2021-10-20 11:35:00",
      open: 185.845,
      low: 185.7644,
      high: 186.43,
      close: 186.21,
      volume: 18678
    },
    {
      date: "2021-10-20 11:34:00",
      open: 186.17,
      low: 185.64,
      high: 186.18,
      close: 185.9866,
      volume: 7855
    },
    {
      date: "2021-10-20 11:33:00",
      open: 186.31,
      low: 186.08,
      high: 186.35,
      close: 186.275,
      volume: 11235
    },
    {
      date: "2021-10-20 11:32:00",
      open: 186.0568,
      low: 185.7945,
      high: 186.36,
      close: 186.36,
      volume: 20984
    },
    {
      date: "2021-10-20 11:31:00",
      open: 186.22,
      low: 185.816,
      high: 186.29,
      close: 186.29,
      volume: 12373
    },
    {
      date: "2021-10-20 11:30:00",
      open: 186.296,
      low: 186.03,
      high: 186.296,
      close: 186.09,
      volume: 7005
    },
    {
      date: "2021-10-20 11:29:00",
      open: 186.07,
      low: 185.9,
      high: 186.42,
      close: 186.22,
      volume: 23018
    },
    {
      date: "2021-10-20 11:28:00",
      open: 186.06,
      low: 185.8982,
      high: 186.13,
      close: 186.13,
      volume: 7256
    },
    {
      date: "2021-10-20 11:27:00",
      open: 186.0,
      low: 185.5,
      high: 186.29,
      close: 185.98,
      volume: 11690
    },
    {
      date: "2021-10-20 11:26:00",
      open: 185.93,
      low: 185.71,
      high: 185.93,
      close: 185.93,
      volume: 4690
    },
    {
      date: "2021-10-20 11:25:00",
      open: 185.91,
      low: 185.71,
      high: 185.93,
      close: 185.9,
      volume: 17603
    },
    {
      date: "2021-10-20 11:24:00",
      open: 185.6453,
      low: 185.548,
      high: 185.91,
      close: 185.77,
      volume: 11067
    },
    {
      date: "2021-10-20 11:23:00",
      open: 185.61,
      low: 185.61,
      high: 185.91,
      close: 185.65,
      volume: 6291
    },
    {
      date: "2021-10-20 11:22:00",
      open: 185.83,
      low: 185.72,
      high: 185.92,
      close: 185.81,
      volume: 14351
    },
    {
      date: "2021-10-20 11:21:00",
      open: 185.89,
      low: 185.31,
      high: 185.92,
      close: 185.92,
      volume: 15755
    },
    {
      date: "2021-10-20 11:20:00",
      open: 185.57,
      low: 185.57,
      high: 185.91,
      close: 185.7891,
      volume: 23057
    },
    {
      date: "2021-10-20 11:19:00",
      open: 185.87,
      low: 185.46,
      high: 185.95,
      close: 185.54,
      volume: 15946
    },
    {
      date: "2021-10-20 11:18:00",
      open: 185.9,
      low: 185.49,
      high: 185.93,
      close: 185.93,
      volume: 25759
    },
    {
      date: "2021-10-20 11:17:00",
      open: 185.76,
      low: 184.84,
      high: 185.91,
      close: 185.87,
      volume: 31370
    },
    {
      date: "2021-10-20 11:16:00",
      open: 185.37,
      low: 185.0,
      high: 186.369,
      close: 185.48,
      volume: 45247
    },
    {
      date: "2021-10-20 11:15:00",
      open: 185.0638,
      low: 185.0638,
      high: 186.4199,
      close: 185.33,
      volume: 32427
    },
    {
      date: "2021-10-20 11:14:00",
      open: 184.2904,
      low: 184.2904,
      high: 185.22,
      close: 184.87,
      volume: 16471
    },
    {
      date: "2021-10-20 11:13:00",
      open: 184.0067,
      low: 183.98,
      high: 184.2,
      close: 184.15,
      volume: 1321
    },
    {
      date: "2021-10-20 11:12:00",
      open: 184.15,
      low: 184.12,
      high: 184.26,
      close: 184.13,
      volume: 4112
    },
    {
      date: "2021-10-20 11:11:00",
      open: 184.0,
      low: 184.0,
      high: 184.33,
      close: 184.16,
      volume: 4232
    },
    {
      date: "2021-10-20 11:10:00",
      open: 183.785,
      low: 183.6201,
      high: 184.16,
      close: 184.1,
      volume: 5253
    },
    {
      date: "2021-10-20 11:09:00",
      open: 183.87,
      low: 183.62,
      high: 184.0473,
      close: 183.62,
      volume: 2668
    },
    {
      date: "2021-10-20 11:08:00",
      open: 183.87,
      low: 183.87,
      high: 183.87,
      close: 183.87,
      volume: 662
    },
    {
      date: "2021-10-20 11:07:00",
      open: 183.77,
      low: 183.7653,
      high: 184.0,
      close: 183.7653,
      volume: 1463
    },
    {
      date: "2021-10-20 11:06:00",
      open: 183.9,
      low: 183.75,
      high: 183.99,
      close: 183.86,
      volume: 4290
    },
    {
      date: "2021-10-20 11:05:00",
      open: 184.16,
      low: 183.8201,
      high: 184.16,
      close: 183.986,
      volume: 3645
    },
    {
      date: "2021-10-20 11:04:00",
      open: 184.611,
      low: 184.0,
      high: 184.775,
      close: 184.0,
      volume: 5886
    },
    {
      date: "2021-10-20 11:03:00",
      open: 184.73,
      low: 184.73,
      high: 184.73,
      close: 184.73,
      volume: 474
    },
    {
      date: "2021-10-20 11:02:00",
      open: 184.9,
      low: 184.6365,
      high: 184.9699,
      close: 184.9634,
      volume: 2119
    },
    {
      date: "2021-10-20 11:01:00",
      open: 184.81,
      low: 184.78,
      high: 184.94,
      close: 184.895,
      volume: 1022
    },
    {
      date: "2021-10-20 11:00:00",
      open: 184.93,
      low: 184.63,
      high: 184.93,
      close: 184.63,
      volume: 3942
    },
    {
      date: "2021-10-20 10:59:00",
      open: 185.14,
      low: 185.0,
      high: 185.17,
      close: 185.15,
      volume: 2060
    },
    {
      date: "2021-10-20 10:58:00",
      open: 185.255,
      low: 185.0,
      high: 185.255,
      close: 185.0,
      volume: 4952
    },
    {
      date: "2021-10-20 10:57:00",
      open: 185.41,
      low: 185.38,
      high: 185.41,
      close: 185.38,
      volume: 2491
    },
    {
      date: "2021-10-20 10:56:00",
      open: 185.5,
      low: 185.3,
      high: 185.61,
      close: 185.3,
      volume: 1448
    },
    {
      date: "2021-10-20 10:55:00",
      open: 185.51,
      low: 185.51,
      high: 185.52,
      close: 185.52,
      volume: 857
    },
    {
      date: "2021-10-20 10:54:00",
      open: 185.71,
      low: 185.71,
      high: 185.89,
      close: 185.89,
      volume: 808
    },
    {
      date: "2021-10-20 10:53:00",
      open: 186.195,
      low: 185.8,
      high: 186.28,
      close: 185.845,
      volume: 5637
    },
    {
      date: "2021-10-20 10:52:00",
      open: 185.5486,
      low: 185.52,
      high: 186.2,
      close: 186.2,
      volume: 7327
    },
    {
      date: "2021-10-20 10:51:00",
      open: 185.36,
      low: 185.36,
      high: 185.88,
      close: 185.65,
      volume: 3278
    },
    {
      date: "2021-10-20 10:50:00",
      open: 185.6041,
      low: 185.4811,
      high: 185.91,
      close: 185.4811,
      volume: 3453
    },
    {
      date: "2021-10-20 10:49:00",
      open: 185.11,
      low: 184.97,
      high: 185.6,
      close: 185.51,
      volume: 6436
    },
    {
      date: "2021-10-20 10:48:00",
      open: 184.9,
      low: 184.88,
      high: 185.12,
      close: 185.12,
      volume: 809
    },
    {
      date: "2021-10-20 10:47:00",
      open: 184.244,
      low: 184.244,
      high: 185.15,
      close: 185.15,
      volume: 4111
    },
    {
      date: "2021-10-20 10:46:00",
      open: 184.3,
      low: 184.3,
      high: 184.51,
      close: 184.51,
      volume: 1838
    },
    {
      date: "2021-10-20 10:45:00",
      open: 184.17,
      low: 184.17,
      high: 184.76,
      close: 184.4,
      volume: 3042
    },
    {
      date: "2021-10-20 10:44:00",
      open: 184.52,
      low: 184.1453,
      high: 184.52,
      close: 184.41,
      volume: 5946
    },
    {
      date: "2021-10-20 10:43:00",
      open: 184.75,
      low: 184.69,
      high: 184.85,
      close: 184.69,
      volume: 1580
    },
    {
      date: "2021-10-20 10:42:00",
      open: 184.68,
      low: 184.68,
      high: 184.86,
      close: 184.86,
      volume: 834
    },
    {
      date: "2021-10-20 10:41:00",
      open: 184.71,
      low: 184.6859,
      high: 184.71,
      close: 184.6859,
      volume: 1133
    },
    {
      date: "2021-10-20 10:40:00",
      open: 185.09,
      low: 184.69,
      high: 185.1,
      close: 184.7504,
      volume: 2860
    },
    {
      date: "2021-10-20 10:39:00",
      open: 185.08,
      low: 185.08,
      high: 185.15,
      close: 185.15,
      volume: 1188
    },
    {
      date: "2021-10-20 10:38:00",
      open: 184.6101,
      low: 184.5001,
      high: 185.14,
      close: 184.9,
      volume: 4557
    },
    {
      date: "2021-10-20 10:37:00",
      open: 185.235,
      low: 184.86,
      high: 185.64,
      close: 184.86,
      volume: 5157
    },
    {
      date: "2021-10-20 10:36:00",
      open: 185.41,
      low: 185.001,
      high: 186.32,
      close: 185.16,
      volume: 22304
    },
    {
      date: "2021-10-20 10:35:00",
      open: 184.645,
      low: 184.2,
      high: 185.54,
      close: 185.3,
      volume: 51185
    },
    {
      date: "2021-10-20 10:34:00",
      open: 185.2,
      low: 184.51,
      high: 185.39,
      close: 184.8131,
      volume: 20205
    },
    {
      date: "2021-10-20 10:33:00",
      open: 185.43,
      low: 184.4,
      high: 185.43,
      close: 185.06,
      volume: 16902
    },
    {
      date: "2021-10-20 10:32:00",
      open: 184.7179,
      low: 184.7179,
      high: 185.69,
      close: 185.14,
      volume: 45498
    },
    {
      date: "2021-10-20 10:31:00",
      open: 184.6567,
      low: 184.3582,
      high: 185.15,
      close: 185.14,
      volume: 19353
    },
    {
      date: "2021-10-20 10:30:00",
      open: 184.93,
      low: 184.69,
      high: 185.15,
      close: 184.7,
      volume: 12727
    },
    {
      date: "2021-10-20 10:29:00",
      open: 185.1907,
      low: 184.87,
      high: 185.34,
      close: 185.13,
      volume: 13119
    },
    {
      date: "2021-10-20 10:28:00",
      open: 184.95,
      low: 184.74,
      high: 185.34,
      close: 185.23,
      volume: 20366
    },
    {
      date: "2021-10-20 10:27:00",
      open: 184.92,
      low: 184.92,
      high: 185.34,
      close: 184.92,
      volume: 47642
    },
    {
      date: "2021-10-20 10:26:00",
      open: 184.41,
      low: 184.41,
      high: 185.11,
      close: 185.02,
      volume: 31386
    },
    {
      date: "2021-10-20 10:25:00",
      open: 184.23,
      low: 184.22,
      high: 185.11,
      close: 185.0,
      volume: 16353
    },
    {
      date: "2021-10-20 10:24:00",
      open: 184.9322,
      low: 184.01,
      high: 185.165,
      close: 184.01,
      volume: 18753
    },
    {
      date: "2021-10-20 10:23:00",
      open: 184.0,
      low: 184.0,
      high: 185.04,
      close: 184.57,
      volume: 20759
    },
    {
      date: "2021-10-20 10:22:00",
      open: 184.28,
      low: 184.16,
      high: 184.435,
      close: 184.16,
      volume: 14832
    },
    {
      date: "2021-10-20 10:21:00",
      open: 183.9383,
      low: 183.9383,
      high: 185.5,
      close: 185.03,
      volume: 24542
    },
    {
      date: "2021-10-20 10:20:00",
      open: 184.055,
      low: 183.99,
      high: 184.59,
      close: 184.28,
      volume: 15645
    },
    {
      date: "2021-10-20 10:19:00",
      open: 183.55,
      low: 183.19,
      high: 184.4,
      close: 184.3113,
      volume: 20498
    },
    {
      date: "2021-10-20 10:18:00",
      open: 183.44,
      low: 183.04,
      high: 184.1958,
      close: 183.6888,
      volume: 12557
    },
    {
      date: "2021-10-20 10:17:00",
      open: 183.01,
      low: 182.985,
      high: 183.32,
      close: 183.2997,
      volume: 10775
    },
    {
      date: "2021-10-20 10:16:00",
      open: 184.11,
      low: 181.98,
      high: 184.12,
      close: 182.82,
      volume: 25635
    },
    {
      date: "2021-10-20 10:15:00",
      open: 184.73,
      low: 184.28,
      high: 184.73,
      close: 184.35,
      volume: 1531
    },
    {
      date: "2021-10-20 10:14:00",
      open: 184.58,
      low: 184.58,
      high: 184.8732,
      close: 184.71,
      volume: 2855
    },
    {
      date: "2021-10-20 10:13:00",
      open: 185.9641,
      low: 183.74,
      high: 185.9641,
      close: 184.51,
      volume: 54543
    },
    {
      date: "2021-10-20 10:12:00",
      open: 185.98,
      low: 185.76,
      high: 186.15,
      close: 186.02,
      volume: 17311
    },
    {
      date: "2021-10-20 10:11:00",
      open: 185.87,
      low: 185.7,
      high: 186.15,
      close: 185.8536,
      volume: 54522
    },
    {
      date: "2021-10-20 10:10:00",
      open: 185.56,
      low: 185.32,
      high: 186.0,
      close: 185.74,
      volume: 7649
    },
    {
      date: "2021-10-20 10:09:00",
      open: 185.76,
      low: 185.73,
      high: 186.1,
      close: 185.96,
      volume: 9825
    },
    {
      date: "2021-10-20 10:08:00",
      open: 186.12,
      low: 185.29,
      high: 186.15,
      close: 186.01,
      volume: 35157
    },
    {
      date: "2021-10-20 10:07:00",
      open: 186.05,
      low: 185.71,
      high: 186.2,
      close: 185.86,
      volume: 24541
    },
    {
      date: "2021-10-20 10:06:00",
      open: 186.07,
      low: 185.4694,
      high: 186.28,
      close: 185.755,
      volume: 26784
    },
    {
      date: "2021-10-20 10:05:00",
      open: 185.36,
      low: 185.36,
      high: 186.07,
      close: 185.92,
      volume: 24269
    },
    {
      date: "2021-10-20 10:04:00",
      open: 184.67,
      low: 184.57,
      high: 185.6044,
      close: 185.21,
      volume: 13229
    },
    {
      date: "2021-10-20 10:03:00",
      open: 184.0,
      low: 184.0,
      high: 185.29,
      close: 185.01,
      volume: 22632
    },
    {
      date: "2021-10-20 10:02:00",
      open: 184.8,
      low: 183.78,
      high: 184.8,
      close: 184.365,
      volume: 28942
    },
    {
      date: "2021-10-20 10:01:00",
      open: 184.94,
      low: 184.6479,
      high: 185.22,
      close: 185.0,
      volume: 7516
    },
    {
      date: "2021-10-20 10:00:00",
      open: 184.955,
      low: 184.725,
      high: 184.955,
      close: 184.7538,
      volume: 1650
    },
    {
      date: "2021-10-20 09:59:00",
      open: 185.0,
      low: 184.5354,
      high: 185.04,
      close: 184.9585,
      volume: 17047
    },
    {
      date: "2021-10-20 09:58:00",
      open: 185.0562,
      low: 185.02,
      high: 185.4469,
      close: 185.06,
      volume: 8316
    },
    {
      date: "2021-10-20 09:57:00",
      open: 185.61,
      low: 184.8555,
      high: 185.61,
      close: 185.0484,
      volume: 7133
    },
    {
      date: "2021-10-20 09:56:00",
      open: 185.275,
      low: 185.06,
      high: 185.92,
      close: 185.06,
      volume: 11734
    },
    {
      date: "2021-10-20 09:55:00",
      open: 184.77,
      low: 184.6,
      high: 185.43,
      close: 185.43,
      volume: 11659
    },
    {
      date: "2021-10-20 09:54:00",
      open: 185.68,
      low: 184.92,
      high: 185.68,
      close: 184.92,
      volume: 13305
    },
    {
      date: "2021-10-20 09:53:00",
      open: 185.23,
      low: 185.225,
      high: 185.54,
      close: 185.54,
      volume: 2532
    },
    {
      date: "2021-10-20 09:52:00",
      open: 185.6981,
      low: 185.44,
      high: 185.73,
      close: 185.73,
      volume: 3288
    },
    {
      date: "2021-10-20 09:51:00",
      open: 185.42,
      low: 185.21,
      high: 185.42,
      close: 185.21,
      volume: 800
    },
    {
      date: "2021-10-20 09:50:00",
      open: 185.895,
      low: 185.11,
      high: 185.895,
      close: 185.11,
      volume: 2907
    },
    {
      date: "2021-10-20 09:49:00",
      open: 185.8359,
      low: 185.2,
      high: 186.1636,
      close: 185.8801,
      volume: 7917
    },
    {
      date: "2021-10-20 09:48:00",
      open: 185.615,
      low: 185.615,
      high: 185.99,
      close: 185.76,
      volume: 1669
    },
    {
      date: "2021-10-20 09:47:00",
      open: 185.6902,
      low: 184.92,
      high: 185.8306,
      close: 185.32,
      volume: 14810
    },
    {
      date: "2021-10-20 09:46:00",
      open: 186.4646,
      low: 185.24,
      high: 186.4646,
      close: 185.51,
      volume: 5666
    },
    {
      date: "2021-10-20 09:45:00",
      open: 186.9152,
      low: 186.2,
      high: 187.3245,
      close: 186.2,
      volume: 3576
    },
    {
      date: "2021-10-20 09:44:00",
      open: 186.23,
      low: 186.23,
      high: 187.24,
      close: 187.185,
      volume: 11845
    },
    {
      date: "2021-10-20 09:43:00",
      open: 186.2116,
      low: 186.01,
      high: 186.37,
      close: 186.01,
      volume: 2171
    },
    {
      date: "2021-10-20 09:42:00",
      open: 185.7338,
      low: 185.7,
      high: 186.15,
      close: 186.14,
      volume: 1693
    },
    {
      date: "2021-10-20 09:41:00",
      open: 185.75,
      low: 185.24,
      high: 185.75,
      close: 185.59,
      volume: 5706
    },
    {
      date: "2021-10-20 09:40:00",
      open: 185.5418,
      low: 185.34,
      high: 185.75,
      close: 185.38,
      volume: 2372
    },
    {
      date: "2021-10-20 09:39:00",
      open: 185.89,
      low: 185.75,
      high: 186.1762,
      close: 185.8,
      volume: 3199
    },
    {
      date: "2021-10-20 09:38:00",
      open: 186.4007,
      low: 186.0,
      high: 186.65,
      close: 186.0,
      volume: 4532
    },
    {
      date: "2021-10-20 09:37:00",
      open: 186.3,
      low: 186.0786,
      high: 186.6999,
      close: 186.5199,
      volume: 6391
    },
    {
      date: "2021-10-20 09:36:00",
      open: 185.66,
      low: 185.22,
      high: 186.475,
      close: 186.1,
      volume: 8756
    },
    {
      date: "2021-10-20 09:35:00",
      open: 184.56,
      low: 184.4,
      high: 185.69,
      close: 185.69,
      volume: 7001
    },
    {
      date: "2021-10-20 09:34:00",
      open: 185.13,
      low: 184.56,
      high: 185.2799,
      close: 184.56,
      volume: 21349
    },
    {
      date: "2021-10-20 09:33:00",
      open: 185.43,
      low: 184.9,
      high: 186.1216,
      close: 185.115,
      volume: 7209
    },
    {
      date: "2021-10-20 09:32:00",
      open: 186.1,
      low: 184.5869,
      high: 186.19,
      close: 185.04,
      volume: 29187
    },
    {
      date: "2021-10-20 09:31:00",
      open: 186.15,
      low: 186.1,
      high: 186.5,
      close: 186.1,
      volume: 3718
    },
    {
      date: "2021-10-20 09:30:00",
      open: 186.33,
      low: 186.3,
      high: 186.99,
      close: 186.99,
      volume: 16059
    },
    {
      date: "2021-10-19 16:00:00",
      open: 186.79,
      low: 186.79,
      high: 186.79,
      close: 186.79,
      volume: 688
    },
    {
      date: "2021-10-19 15:59:00",
      open: 186.88,
      low: 186.73,
      high: 187.0,
      close: 186.8,
      volume: 21559
    },
    {
      date: "2021-10-19 15:58:00",
      open: 186.88,
      low: 186.73,
      high: 186.9894,
      close: 186.75,
      volume: 6116
    },
    {
      date: "2021-10-19 15:57:00",
      open: 186.785,
      low: 186.6789,
      high: 187.0,
      close: 186.9,
      volume: 5063
    },
    {
      date: "2021-10-19 15:56:00",
      open: 186.632,
      low: 186.632,
      high: 186.78,
      close: 186.6617,
      volume: 1728
    },
    {
      date: "2021-10-19 15:55:00",
      open: 186.65,
      low: 186.635,
      high: 186.78,
      close: 186.635,
      volume: 3477
    },
    {
      date: "2021-10-19 15:54:00",
      open: 186.74,
      low: 186.6345,
      high: 186.8588,
      close: 186.6658,
      volume: 5963
    },
    {
      date: "2021-10-19 15:53:00",
      open: 186.68,
      low: 186.65,
      high: 186.76,
      close: 186.76,
      volume: 2668
    },
    {
      date: "2021-10-19 15:52:00",
      open: 186.73,
      low: 186.62,
      high: 186.76,
      close: 186.62,
      volume: 3526
    },
    {
      date: "2021-10-19 15:51:00",
      open: 186.72,
      low: 186.52,
      high: 187.0,
      close: 186.69,
      volume: 13144
    },
    {
      date: "2021-10-19 15:50:00",
      open: 186.89,
      low: 186.74,
      high: 187.145,
      close: 186.74,
      volume: 10416
    },
    {
      date: "2021-10-19 15:49:00",
      open: 186.81,
      low: 186.75,
      high: 187.0,
      close: 186.79,
      volume: 6571
    },
    {
      date: "2021-10-19 15:48:00",
      open: 186.93,
      low: 186.73,
      high: 187.22,
      close: 186.73,
      volume: 11641
    },
    {
      date: "2021-10-19 15:47:00",
      open: 186.87,
      low: 186.87,
      high: 187.23,
      close: 187.23,
      volume: 2680
    },
    {
      date: "2021-10-19 15:46:00",
      open: 186.9947,
      low: 186.9947,
      high: 186.9947,
      close: 186.9947,
      volume: 709
    },
    {
      date: "2021-10-19 15:45:00",
      open: 186.68,
      low: 186.68,
      high: 186.98,
      close: 186.98,
      volume: 4397
    },
    {
      date: "2021-10-19 15:44:00",
      open: 186.8284,
      low: 186.79,
      high: 186.92,
      close: 186.79,
      volume: 2306
    },
    {
      date: "2021-10-19 15:43:00",
      open: 186.91,
      low: 186.81,
      high: 187.0,
      close: 186.82,
      volume: 3369
    },
    {
      date: "2021-10-19 15:41:00",
      open: 187.09,
      low: 186.79,
      high: 187.11,
      close: 186.88,
      volume: 3551
    },
    {
      date: "2021-10-19 15:40:00",
      open: 187.1999,
      low: 187.18,
      high: 187.29,
      close: 187.25,
      volume: 5289
    },
    {
      date: "2021-10-19 15:39:00",
      open: 187.3,
      low: 187.1512,
      high: 187.3,
      close: 187.1948,
      volume: 8966
    },
    {
      date: "2021-10-19 15:38:00",
      open: 187.53,
      low: 187.21,
      high: 187.53,
      close: 187.21,
      volume: 1292
    },
    {
      date: "2021-10-19 15:37:00",
      open: 187.1869,
      low: 187.1869,
      high: 187.55,
      close: 187.53,
      volume: 27665
    },
    {
      date: "2021-10-19 15:36:00",
      open: 186.8469,
      low: 186.8469,
      high: 187.16,
      close: 187.16,
      volume: 3686
    },
    {
      date: "2021-10-19 15:35:00",
      open: 186.7452,
      low: 186.7452,
      high: 186.99,
      close: 186.99,
      volume: 3616
    },
    {
      date: "2021-10-19 15:34:00",
      open: 186.6746,
      low: 186.6579,
      high: 186.75,
      close: 186.75,
      volume: 1322
    },
    {
      date: "2021-10-19 15:33:00",
      open: 186.743,
      low: 186.743,
      high: 186.743,
      close: 186.743,
      volume: 1165
    },
    {
      date: "2021-10-19 15:32:00",
      open: 186.8678,
      low: 186.67,
      high: 186.8678,
      close: 186.6862,
      volume: 2226
    },
    {
      date: "2021-10-19 15:31:00",
      open: 187.07,
      low: 186.86,
      high: 187.07,
      close: 187.05,
      volume: 2570
    },
    {
      date: "2021-10-19 15:30:00",
      open: 187.32,
      low: 187.3,
      high: 187.32,
      close: 187.3,
      volume: 887
    },
    {
      date: "2021-10-19 15:29:00",
      open: 187.38,
      low: 187.145,
      high: 187.4,
      close: 187.145,
      volume: 4713
    },
    {
      date: "2021-10-19 15:27:00",
      open: 187.4999,
      low: 187.32,
      high: 187.555,
      close: 187.555,
      volume: 1190
    },
    {
      date: "2021-10-19 15:26:00",
      open: 187.64,
      low: 187.32,
      high: 187.64,
      close: 187.32,
      volume: 880
    },
    {
      date: "2021-10-19 15:25:00",
      open: 187.19,
      low: 187.19,
      high: 187.5956,
      close: 187.5956,
      volume: 1956
    },
    {
      date: "2021-10-19 15:24:00",
      open: 187.36,
      low: 187.36,
      high: 187.36,
      close: 187.36,
      volume: 782
    },
    {
      date: "2021-10-19 15:23:00",
      open: 187.475,
      low: 187.47,
      high: 187.52,
      close: 187.47,
      volume: 1727
    },
    {
      date: "2021-10-19 15:22:00",
      open: 187.57,
      low: 187.45,
      high: 187.57,
      close: 187.45,
      volume: 1744
    },
    {
      date: "2021-10-19 15:21:00",
      open: 187.77,
      low: 187.5493,
      high: 187.77,
      close: 187.63,
      volume: 3304
    },
    {
      date: "2021-10-19 15:20:00",
      open: 187.77,
      low: 187.77,
      high: 187.97,
      close: 187.77,
      volume: 2451
    },
    {
      date: "2021-10-19 15:19:00",
      open: 187.79,
      low: 187.75,
      high: 187.897,
      close: 187.897,
      volume: 2379
    },
    {
      date: "2021-10-19 15:18:00",
      open: 187.57,
      low: 187.57,
      high: 187.79,
      close: 187.655,
      volume: 2685
    },
    {
      date: "2021-10-19 15:16:00",
      open: 187.55,
      low: 187.4,
      high: 187.55,
      close: 187.4,
      volume: 497
    },
    {
      date: "2021-10-19 15:15:00",
      open: 187.34,
      low: 187.34,
      high: 187.68,
      close: 187.51,
      volume: 6191
    },
    {
      date: "2021-10-19 15:14:00",
      open: 187.185,
      low: 187.185,
      high: 187.185,
      close: 187.185,
      volume: 647
    },
    {
      date: "2021-10-19 15:13:00",
      open: 187.16,
      low: 187.0301,
      high: 187.33,
      close: 187.14,
      volume: 1608
    },
    {
      date: "2021-10-19 15:12:00",
      open: 187.03,
      low: 187.03,
      high: 187.03,
      close: 187.03,
      volume: 655
    },
    {
      date: "2021-10-19 15:11:00",
      open: 186.98,
      low: 186.89,
      high: 187.2,
      close: 187.03,
      volume: 4613
    },
    {
      date: "2021-10-19 15:10:00",
      open: 186.7,
      low: 186.7,
      high: 186.96,
      close: 186.86,
      volume: 1898
    },
    {
      date: "2021-10-19 15:09:00",
      open: 186.63,
      low: 186.63,
      high: 186.9318,
      close: 186.9318,
      volume: 1424
    },
    {
      date: "2021-10-19 15:08:00",
      open: 186.749,
      low: 186.74,
      high: 186.81,
      close: 186.74,
      volume: 1221
    },
    {
      date: "2021-10-19 15:07:00",
      open: 186.705,
      low: 186.59,
      high: 186.78,
      close: 186.735,
      volume: 2513
    },
    {
      date: "2021-10-19 15:06:00",
      open: 186.87,
      low: 186.7849,
      high: 186.87,
      close: 186.7849,
      volume: 894
    },
    {
      date: "2021-10-19 15:05:00",
      open: 186.85,
      low: 186.85,
      high: 186.85,
      close: 186.85,
      volume: 617
    },
    {
      date: "2021-10-19 15:04:00",
      open: 186.7799,
      low: 186.6348,
      high: 186.86,
      close: 186.86,
      volume: 2474
    },
    {
      date: "2021-10-19 15:03:00",
      open: 186.8284,
      low: 186.8284,
      high: 186.8284,
      close: 186.8284,
      volume: 764
    },
    {
      date: "2021-10-19 15:02:00",
      open: 186.84,
      low: 186.8,
      high: 186.94,
      close: 186.8,
      volume: 2081
    },
    {
      date: "2021-10-19 15:01:00",
      open: 186.67,
      low: 186.615,
      high: 186.67,
      close: 186.615,
      volume: 984
    },
    {
      date: "2021-10-19 15:00:00",
      open: 186.94,
      low: 186.94,
      high: 186.94,
      close: 186.94,
      volume: 260
    },
    {
      date: "2021-10-19 14:59:00",
      open: 186.92,
      low: 186.74,
      high: 186.93,
      close: 186.74,
      volume: 1621
    },
    {
      date: "2021-10-19 14:58:00",
      open: 187.01,
      low: 186.9628,
      high: 187.08,
      close: 186.9629,
      volume: 1143
    },
    {
      date: "2021-10-19 14:57:00",
      open: 186.94,
      low: 186.91,
      high: 187.0357,
      close: 186.91,
      volume: 2833
    },
    {
      date: "2021-10-19 14:56:00",
      open: 186.84,
      low: 186.84,
      high: 187.0114,
      close: 187.0,
      volume: 1533
    },
    {
      date: "2021-10-19 14:55:00",
      open: 186.32,
      low: 186.3,
      high: 186.71,
      close: 186.71,
      volume: 3745
    },
    {
      date: "2021-10-19 14:54:00",
      open: 186.3201,
      low: 186.3201,
      high: 186.3201,
      close: 186.3201,
      volume: 217
    },
    {
      date: "2021-10-19 14:53:00",
      open: 186.41,
      low: 186.31,
      high: 186.44,
      close: 186.31,
      volume: 1043
    },
    {
      date: "2021-10-19 14:52:00",
      open: 186.355,
      low: 186.355,
      high: 186.4406,
      close: 186.4406,
      volume: 985
    },
    {
      date: "2021-10-19 14:51:00",
      open: 186.43,
      low: 186.21,
      high: 186.43,
      close: 186.3753,
      volume: 4614
    },
    {
      date: "2021-10-19 14:50:00",
      open: 186.465,
      low: 186.465,
      high: 186.61,
      close: 186.61,
      volume: 748
    },
    {
      date: "2021-10-19 14:49:00",
      open: 186.505,
      low: 186.505,
      high: 186.51,
      close: 186.51,
      volume: 638
    },
    {
      date: "2021-10-19 14:47:00",
      open: 186.524,
      low: 186.524,
      high: 186.524,
      close: 186.524,
      volume: 1401
    },
    {
      date: "2021-10-19 14:46:00",
      open: 186.455,
      low: 186.455,
      high: 186.455,
      close: 186.455,
      volume: 320
    },
    {
      date: "2021-10-19 14:45:00",
      open: 186.46,
      low: 186.3782,
      high: 186.5,
      close: 186.3782,
      volume: 2930
    },
    {
      date: "2021-10-19 14:44:00",
      open: 186.4764,
      low: 186.46,
      high: 186.4764,
      close: 186.46,
      volume: 612
    },
    {
      date: "2021-10-19 14:42:00",
      open: 186.52,
      low: 186.52,
      high: 186.52,
      close: 186.52,
      volume: 188
    },
    {
      date: "2021-10-19 14:41:00",
      open: 186.58,
      low: 186.4,
      high: 186.61,
      close: 186.61,
      volume: 3998
    },
    {
      date: "2021-10-19 14:40:00",
      open: 186.7656,
      low: 186.67,
      high: 186.9,
      close: 186.67,
      volume: 1505
    },
    {
      date: "2021-10-19 14:39:00",
      open: 186.78,
      low: 186.66,
      high: 186.79,
      close: 186.79,
      volume: 1628
    },
    {
      date: "2021-10-19 14:38:00",
      open: 186.84,
      low: 186.805,
      high: 186.84,
      close: 186.805,
      volume: 693
    },
    {
      date: "2021-10-19 14:37:00",
      open: 186.8016,
      low: 186.66,
      high: 186.96,
      close: 186.7896,
      volume: 3194
    },
    {
      date: "2021-10-19 14:36:00",
      open: 186.805,
      low: 186.805,
      high: 186.82,
      close: 186.82,
      volume: 483
    },
    {
      date: "2021-10-19 14:35:00",
      open: 186.8023,
      low: 186.8023,
      high: 186.815,
      close: 186.805,
      volume: 502
    },
    {
      date: "2021-10-19 14:34:00",
      open: 186.74,
      low: 186.74,
      high: 186.81,
      close: 186.81,
      volume: 378
    },
    {
      date: "2021-10-19 14:33:00",
      open: 186.92,
      low: 186.66,
      high: 186.971,
      close: 186.971,
      volume: 3395
    },
    {
      date: "2021-10-19 14:32:00",
      open: 187.115,
      low: 186.92,
      high: 187.115,
      close: 186.9246,
      volume: 1222
    },
    {
      date: "2021-10-19 14:31:00",
      open: 187.4,
      low: 186.97,
      high: 187.41,
      close: 187.13,
      volume: 2732
    },
    {
      date: "2021-10-19 14:30:00",
      open: 187.3915,
      low: 187.3915,
      high: 187.3915,
      close: 187.3915,
      volume: 344
    },
    {
      date: "2021-10-19 14:29:00",
      open: 187.13,
      low: 187.13,
      high: 187.28,
      close: 187.2136,
      volume: 1010
    },
    {
      date: "2021-10-19 14:28:00",
      open: 187.22,
      low: 186.88,
      high: 187.22,
      close: 186.92,
      volume: 1925
    },
    {
      date: "2021-10-19 14:27:00",
      open: 187.4165,
      low: 187.38,
      high: 187.49,
      close: 187.49,
      volume: 1620
    },
    {
      date: "2021-10-19 14:26:00",
      open: 187.5,
      low: 187.5,
      high: 187.5,
      close: 187.5,
      volume: 532
    },
    {
      date: "2021-10-19 14:25:00",
      open: 187.49,
      low: 187.2752,
      high: 187.52,
      close: 187.52,
      volume: 1325
    },
    {
      date: "2021-10-19 14:24:00",
      open: 187.65,
      low: 187.39,
      high: 187.7,
      close: 187.49,
      volume: 1640
    },
    {
      date: "2021-10-19 14:23:00",
      open: 187.69,
      low: 187.27,
      high: 187.69,
      close: 187.56,
      volume: 2719
    },
    {
      date: "2021-10-19 14:22:00",
      open: 187.99,
      low: 187.56,
      high: 188.109,
      close: 187.63,
      volume: 7670
    },
    {
      date: "2021-10-19 14:21:00",
      open: 187.76,
      low: 187.66,
      high: 188.26,
      close: 188.0,
      volume: 11719
    },
    {
      date: "2021-10-19 14:20:00",
      open: 187.28,
      low: 187.245,
      high: 187.748,
      close: 187.748,
      volume: 8843
    },
    {
      date: "2021-10-19 14:19:00",
      open: 186.9374,
      low: 186.9374,
      high: 187.39,
      close: 187.3502,
      volume: 2943
    },
    {
      date: "2021-10-19 14:18:00",
      open: 186.79,
      low: 186.6826,
      high: 186.79,
      close: 186.6826,
      volume: 2790
    },
    {
      date: "2021-10-19 14:17:00",
      open: 186.68,
      low: 186.61,
      high: 186.68,
      close: 186.61,
      volume: 1279
    },
    {
      date: "2021-10-19 14:16:00",
      open: 186.755,
      low: 186.755,
      high: 186.97,
      close: 186.96,
      volume: 1069
    },
    {
      date: "2021-10-19 14:15:00",
      open: 187.28,
      low: 186.7919,
      high: 187.34,
      close: 186.7919,
      volume: 3056
    },
    {
      date: "2021-10-19 14:14:00",
      open: 187.31,
      low: 187.195,
      high: 187.32,
      close: 187.2,
      volume: 2781
    },
    {
      date: "2021-10-19 14:13:00",
      open: 187.26,
      low: 187.2563,
      high: 187.3,
      close: 187.3,
      volume: 1757
    },
    {
      date: "2021-10-19 14:12:00",
      open: 187.48,
      low: 187.37,
      high: 187.55,
      close: 187.55,
      volume: 1952
    },
    {
      date: "2021-10-19 14:11:00",
      open: 187.3,
      low: 187.1955,
      high: 187.5,
      close: 187.435,
      volume: 7931
    },
    {
      date: "2021-10-19 14:10:00",
      open: 186.67,
      low: 186.67,
      high: 187.34,
      close: 187.31,
      volume: 8519
    },
    {
      date: "2021-10-19 14:09:00",
      open: 186.335,
      low: 186.335,
      high: 186.72,
      close: 186.595,
      volume: 2707
    },
    {
      date: "2021-10-19 14:08:00",
      open: 186.27,
      low: 186.27,
      high: 186.41,
      close: 186.41,
      volume: 1213
    },
    {
      date: "2021-10-19 14:07:00",
      open: 186.3,
      low: 186.0375,
      high: 186.32,
      close: 186.0375,
      volume: 1241
    },
    {
      date: "2021-10-19 14:06:00",
      open: 186.18,
      low: 186.157,
      high: 186.18,
      close: 186.157,
      volume: 1385
    },
    {
      date: "2021-10-19 14:04:00",
      open: 186.34,
      low: 186.17,
      high: 186.34,
      close: 186.28,
      volume: 1389
    },
    {
      date: "2021-10-19 14:03:00",
      open: 186.2,
      low: 186.2,
      high: 186.33,
      close: 186.33,
      volume: 527
    },
    {
      date: "2021-10-19 14:02:00",
      open: 186.27,
      low: 186.2,
      high: 186.27,
      close: 186.2,
      volume: 695
    },
    {
      date: "2021-10-19 14:01:00",
      open: 186.34,
      low: 186.34,
      high: 186.37,
      close: 186.37,
      volume: 1208
    },
    {
      date: "2021-10-19 14:00:00",
      open: 186.39,
      low: 186.3,
      high: 186.39,
      close: 186.3,
      volume: 876
    },
    {
      date: "2021-10-19 13:59:00",
      open: 186.8399,
      low: 186.5021,
      high: 186.8399,
      close: 186.52,
      volume: 2079
    },
    {
      date: "2021-10-19 13:58:00",
      open: 186.71,
      low: 186.65,
      high: 186.85,
      close: 186.65,
      volume: 1904
    },
    {
      date: "2021-10-19 13:57:00",
      open: 186.57,
      low: 186.475,
      high: 186.62,
      close: 186.62,
      volume: 1447
    },
    {
      date: "2021-10-19 13:56:00",
      open: 186.28,
      low: 186.28,
      high: 186.46,
      close: 186.46,
      volume: 1020
    },
    {
      date: "2021-10-19 13:55:00",
      open: 186.3159,
      low: 186.3159,
      high: 186.3343,
      close: 186.3343,
      volume: 745
    },
    {
      date: "2021-10-19 13:54:00",
      open: 186.25,
      low: 186.25,
      high: 186.39,
      close: 186.3121,
      volume: 2074
    },
    {
      date: "2021-10-19 13:53:00",
      open: 186.55,
      low: 186.34,
      high: 186.55,
      close: 186.37,
      volume: 1327
    },
    {
      date: "2021-10-19 13:52:00",
      open: 186.5786,
      low: 186.38,
      high: 186.649,
      close: 186.55,
      volume: 3961
    },
    {
      date: "2021-10-19 13:51:00",
      open: 186.49,
      low: 186.4397,
      high: 186.61,
      close: 186.4397,
      volume: 1194
    },
    {
      date: "2021-10-19 13:50:00",
      open: 186.26,
      low: 186.26,
      high: 186.6,
      close: 186.43,
      volume: 3078
    },
    {
      date: "2021-10-19 13:49:00",
      open: 186.44,
      low: 186.2,
      high: 186.44,
      close: 186.2826,
      volume: 3930
    },
    {
      date: "2021-10-19 13:48:00",
      open: 186.15,
      low: 186.15,
      high: 186.355,
      close: 186.355,
      volume: 1814
    },
    {
      date: "2021-10-19 13:47:00",
      open: 186.09,
      low: 185.92,
      high: 186.15,
      close: 185.96,
      volume: 2477
    },
    {
      date: "2021-10-19 13:46:00",
      open: 186.11,
      low: 185.8272,
      high: 186.28,
      close: 185.8272,
      volume: 5730
    },
    {
      date: "2021-10-19 13:45:00",
      open: 186.225,
      low: 186.16,
      high: 186.225,
      close: 186.1669,
      volume: 652
    },
    {
      date: "2021-10-19 13:44:00",
      open: 186.34,
      low: 186.33,
      high: 186.34,
      close: 186.33,
      volume: 726
    },
    {
      date: "2021-10-19 13:43:00",
      open: 186.43,
      low: 186.27,
      high: 186.5018,
      close: 186.27,
      volume: 2563
    },
    {
      date: "2021-10-19 13:42:00",
      open: 186.595,
      low: 186.42,
      high: 186.595,
      close: 186.42,
      volume: 713
    },
    {
      date: "2021-10-19 13:41:00",
      open: 186.5,
      low: 186.5,
      high: 186.84,
      close: 186.59,
      volume: 3261
    },
    {
      date: "2021-10-19 13:40:00",
      open: 186.53,
      low: 186.46,
      high: 186.6,
      close: 186.46,
      volume: 1096
    },
    {
      date: "2021-10-19 13:39:00",
      open: 186.511,
      low: 186.5001,
      high: 186.65,
      close: 186.5001,
      volume: 2107
    },
    {
      date: "2021-10-19 13:38:00",
      open: 186.55,
      low: 186.35,
      high: 186.55,
      close: 186.4878,
      volume: 2564
    },
    {
      date: "2021-10-19 13:37:00",
      open: 186.52,
      low: 186.4001,
      high: 186.52,
      close: 186.4001,
      volume: 690
    },
    {
      date: "2021-10-19 13:35:00",
      open: 186.325,
      low: 186.325,
      high: 186.325,
      close: 186.325,
      volume: 386
    },
    {
      date: "2021-10-19 13:34:00",
      open: 186.35,
      low: 186.23,
      high: 186.37,
      close: 186.23,
      volume: 1363
    },
    {
      date: "2021-10-19 13:33:00",
      open: 186.6599,
      low: 186.4,
      high: 186.6599,
      close: 186.4,
      volume: 3013
    },
    {
      date: "2021-10-19 13:32:00",
      open: 186.91,
      low: 186.65,
      high: 186.91,
      close: 186.66,
      volume: 3650
    },
    {
      date: "2021-10-19 13:31:00",
      open: 186.83,
      low: 186.78,
      high: 186.83,
      close: 186.79,
      volume: 817
    },
    {
      date: "2021-10-19 13:30:00",
      open: 186.95,
      low: 186.95,
      high: 186.95,
      close: 186.95,
      volume: 352
    },
    {
      date: "2021-10-19 13:29:00",
      open: 187.11,
      low: 187.0,
      high: 187.11,
      close: 187.0,
      volume: 334
    },
    {
      date: "2021-10-19 13:28:00",
      open: 186.99,
      low: 186.99,
      high: 186.99,
      close: 186.99,
      volume: 491
    },
    {
      date: "2021-10-19 13:27:00",
      open: 186.875,
      low: 186.875,
      high: 186.99,
      close: 186.99,
      volume: 462
    },
    {
      date: "2021-10-19 13:25:00",
      open: 186.91,
      low: 186.79,
      high: 186.91,
      close: 186.79,
      volume: 584
    },
    {
      date: "2021-10-19 13:24:00",
      open: 187.17,
      low: 186.85,
      high: 187.17,
      close: 186.9,
      volume: 3544
    },
    {
      date: "2021-10-19 13:23:00",
      open: 187.1376,
      low: 187.1,
      high: 187.18,
      close: 187.1,
      volume: 1280
    },
    {
      date: "2021-10-19 13:22:00",
      open: 187.13,
      low: 187.1,
      high: 187.18,
      close: 187.18,
      volume: 1332
    },
    {
      date: "2021-10-19 13:21:00",
      open: 187.03,
      low: 186.9,
      high: 187.0609,
      close: 187.0609,
      volume: 1466
    },
    {
      date: "2021-10-19 13:20:00",
      open: 187.06,
      low: 186.9,
      high: 187.11,
      close: 187.09,
      volume: 4464
    },
    {
      date: "2021-10-19 13:19:00",
      open: 186.92,
      low: 186.92,
      high: 187.0,
      close: 187.0,
      volume: 1581
    },
    {
      date: "2021-10-19 13:18:00",
      open: 186.96,
      low: 186.9248,
      high: 186.96,
      close: 186.9248,
      volume: 2862
    },
    {
      date: "2021-10-19 13:17:00",
      open: 186.98,
      low: 186.73,
      high: 186.98,
      close: 186.73,
      volume: 1179
    },
    {
      date: "2021-10-19 13:16:00",
      open: 186.94,
      low: 186.94,
      high: 187.08,
      close: 186.975,
      volume: 727
    },
    {
      date: "2021-10-19 13:14:00",
      open: 187.0,
      low: 186.845,
      high: 187.0329,
      close: 187.03,
      volume: 2481
    },
    {
      date: "2021-10-19 13:13:00",
      open: 187.16,
      low: 187.045,
      high: 187.16,
      close: 187.045,
      volume: 553
    },
    {
      date: "2021-10-19 13:11:00",
      open: 187.13,
      low: 187.13,
      high: 187.13,
      close: 187.13,
      volume: 382
    },
    {
      date: "2021-10-19 13:10:00",
      open: 186.95,
      low: 186.95,
      high: 187.12,
      close: 187.12,
      volume: 1332
    },
    {
      date: "2021-10-19 13:08:00",
      open: 186.9946,
      low: 186.7001,
      high: 186.9946,
      close: 186.96,
      volume: 1031
    },
    {
      date: "2021-10-19 13:07:00",
      open: 187.06,
      low: 186.881,
      high: 187.1,
      close: 187.04,
      volume: 2341
    },
    {
      date: "2021-10-19 13:06:00",
      open: 187.15,
      low: 187.1,
      high: 187.2299,
      close: 187.2299,
      volume: 990
    },
    {
      date: "2021-10-19 13:05:00",
      open: 187.25,
      low: 187.23,
      high: 187.25,
      close: 187.25,
      volume: 765
    },
    {
      date: "2021-10-19 13:04:00",
      open: 187.27,
      low: 187.27,
      high: 187.3099,
      close: 187.3099,
      volume: 724
    },
    {
      date: "2021-10-19 13:03:00",
      open: 187.08,
      low: 187.06,
      high: 187.13,
      close: 187.06,
      volume: 1263
    },
    {
      date: "2021-10-19 13:02:00",
      open: 187.2131,
      low: 187.2131,
      high: 187.2131,
      close: 187.2131,
      volume: 768
    },
    {
      date: "2021-10-19 13:01:00",
      open: 187.46,
      low: 187.46,
      high: 187.46,
      close: 187.46,
      volume: 632
    },
    {
      date: "2021-10-19 13:00:00",
      open: 187.62,
      low: 187.43,
      high: 187.62,
      close: 187.46,
      volume: 1095
    },
    {
      date: "2021-10-19 12:59:00",
      open: 187.42,
      low: 187.42,
      high: 187.5,
      close: 187.5,
      volume: 1952
    },
    {
      date: "2021-10-19 12:58:00",
      open: 187.09,
      low: 187.09,
      high: 187.47,
      close: 187.46,
      volume: 2741
    },
    {
      date: "2021-10-19 12:57:00",
      open: 187.3378,
      low: 187.06,
      high: 187.3378,
      close: 187.09,
      volume: 1949
    },
    {
      date: "2021-10-19 12:56:00",
      open: 187.281,
      low: 187.2476,
      high: 187.315,
      close: 187.2476,
      volume: 941
    },
    {
      date: "2021-10-19 12:55:00",
      open: 187.275,
      low: 187.22,
      high: 187.2929,
      close: 187.2701,
      volume: 1077
    },
    {
      date: "2021-10-19 12:54:00",
      open: 187.5993,
      low: 187.38,
      high: 187.5993,
      close: 187.38,
      volume: 1626
    },
    {
      date: "2021-10-19 12:53:00",
      open: 187.15,
      low: 187.1075,
      high: 187.63,
      close: 187.57,
      volume: 11656
    },
    {
      date: "2021-10-19 12:52:00",
      open: 186.61,
      low: 186.61,
      high: 187.18,
      close: 187.15,
      volume: 6835
    },
    {
      date: "2021-10-19 12:51:00",
      open: 186.39,
      low: 186.39,
      high: 186.63,
      close: 186.63,
      volume: 1486
    },
    {
      date: "2021-10-19 12:50:00",
      open: 186.28,
      low: 186.28,
      high: 186.44,
      close: 186.44,
      volume: 2355
    },
    {
      date: "2021-10-19 12:48:00",
      open: 186.37,
      low: 186.2589,
      high: 186.43,
      close: 186.43,
      volume: 1135
    },
    {
      date: "2021-10-19 12:47:00",
      open: 186.27,
      low: 186.27,
      high: 186.3961,
      close: 186.29,
      volume: 1607
    },
    {
      date: "2021-10-19 12:46:00",
      open: 185.98,
      low: 185.96,
      high: 186.34,
      close: 186.34,
      volume: 1874
    },
    {
      date: "2021-10-19 12:45:00",
      open: 186.36,
      low: 186.0,
      high: 186.36,
      close: 186.0,
      volume: 3486
    },
    {
      date: "2021-10-19 12:44:00",
      open: 186.23,
      low: 186.21,
      high: 186.37,
      close: 186.21,
      volume: 790
    },
    {
      date: "2021-10-19 12:43:00",
      open: 186.305,
      low: 186.2899,
      high: 186.32,
      close: 186.32,
      volume: 1271
    },
    {
      date: "2021-10-19 12:42:00",
      open: 186.19,
      low: 186.19,
      high: 186.34,
      close: 186.28,
      volume: 1017
    },
    {
      date: "2021-10-19 12:41:00",
      open: 186.0701,
      low: 186.0701,
      high: 186.34,
      close: 186.34,
      volume: 764
    },
    {
      date: "2021-10-19 12:40:00",
      open: 186.085,
      low: 186.085,
      high: 186.11,
      close: 186.11,
      volume: 618
    },
    {
      date: "2021-10-19 12:39:00",
      open: 186.195,
      low: 186.195,
      high: 186.195,
      close: 186.195,
      volume: 317
    },
    {
      date: "2021-10-19 12:38:00",
      open: 186.11,
      low: 186.0,
      high: 186.3468,
      close: 186.3468,
      volume: 1338
    },
    {
      date: "2021-10-19 12:37:00",
      open: 186.3,
      low: 186.3,
      high: 186.3,
      close: 186.3,
      volume: 757
    },
    {
      date: "2021-10-19 12:36:00",
      open: 186.2583,
      low: 186.2583,
      high: 186.2583,
      close: 186.2583,
      volume: 1188
    },
    {
      date: "2021-10-19 12:35:00",
      open: 185.8972,
      low: 185.5,
      high: 186.14,
      close: 186.14,
      volume: 7729
    },
    {
      date: "2021-10-19 12:34:00",
      open: 185.96,
      low: 185.7501,
      high: 186.1,
      close: 185.7501,
      volume: 1976
    },
    {
      date: "2021-10-19 12:33:00",
      open: 186.53,
      low: 185.73,
      high: 186.53,
      close: 185.74,
      volume: 7255
    },
    {
      date: "2021-10-19 12:32:00",
      open: 186.6,
      low: 186.5,
      high: 186.77,
      close: 186.58,
      volume: 3139
    },
    {
      date: "2021-10-19 12:30:00",
      open: 186.59,
      low: 186.59,
      high: 186.59,
      close: 186.59,
      volume: 1307
    },
    {
      date: "2021-10-19 12:29:00",
      open: 186.9,
      low: 186.86,
      high: 187.129,
      close: 186.92,
      volume: 1755
    },
    {
      date: "2021-10-19 12:27:00",
      open: 186.9099,
      low: 186.8803,
      high: 187.0321,
      close: 187.0321,
      volume: 1853
    },
    {
      date: "2021-10-19 12:26:00",
      open: 186.87,
      low: 186.86,
      high: 186.87,
      close: 186.87,
      volume: 1197
    },
    {
      date: "2021-10-19 12:25:00",
      open: 186.7501,
      low: 186.51,
      high: 186.77,
      close: 186.51,
      volume: 1443
    },
    {
      date: "2021-10-19 12:24:00",
      open: 187.04,
      low: 186.7,
      high: 187.05,
      close: 186.7,
      volume: 25266
    },
    {
      date: "2021-10-19 12:23:00",
      open: 187.15,
      low: 187.15,
      high: 187.15,
      close: 187.15,
      volume: 659
    },
    {
      date: "2021-10-19 12:21:00",
      open: 187.09,
      low: 187.0383,
      high: 187.33,
      close: 187.33,
      volume: 1357
    },
    {
      date: "2021-10-19 12:20:00",
      open: 187.21,
      low: 187.21,
      high: 187.38,
      close: 187.38,
      volume: 763
    },
    {
      date: "2021-10-19 12:19:00",
      open: 187.31,
      low: 187.31,
      high: 187.31,
      close: 187.31,
      volume: 490
    },
    {
      date: "2021-10-19 12:18:00",
      open: 187.1247,
      low: 187.0208,
      high: 187.14,
      close: 187.14,
      volume: 1859
    },
    {
      date: "2021-10-19 12:17:00",
      open: 187.45,
      low: 187.0,
      high: 187.45,
      close: 187.0365,
      volume: 7187
    },
    {
      date: "2021-10-19 12:16:00",
      open: 187.5828,
      low: 187.5828,
      high: 187.5828,
      close: 187.5828,
      volume: 451
    },
    {
      date: "2021-10-19 12:15:00",
      open: 187.43,
      low: 187.43,
      high: 187.7146,
      close: 187.7146,
      volume: 521
    },
    {
      date: "2021-10-19 12:14:00",
      open: 187.5,
      low: 187.5,
      high: 187.5,
      close: 187.5,
      volume: 918
    },
    {
      date: "2021-10-19 12:13:00",
      open: 187.79,
      low: 187.65,
      high: 187.92,
      close: 187.65,
      volume: 1459
    },
    {
      date: "2021-10-19 12:12:00",
      open: 187.65,
      low: 187.65,
      high: 187.65,
      close: 187.65,
      volume: 295
    },
    {
      date: "2021-10-19 12:11:00",
      open: 187.75,
      low: 187.75,
      high: 187.85,
      close: 187.85,
      volume: 775
    },
    {
      date: "2021-10-19 12:10:00",
      open: 187.8788,
      low: 187.8303,
      high: 187.8788,
      close: 187.8303,
      volume: 686
    },
    {
      date: "2021-10-19 12:09:00",
      open: 187.775,
      low: 187.775,
      high: 187.775,
      close: 187.775,
      volume: 299
    },
    {
      date: "2021-10-19 12:08:00",
      open: 187.65,
      low: 187.65,
      high: 187.79,
      close: 187.79,
      volume: 1658
    },
    {
      date: "2021-10-19 12:07:00",
      open: 187.51,
      low: 187.51,
      high: 187.51,
      close: 187.51,
      volume: 571
    },
    {
      date: "2021-10-19 12:06:00",
      open: 187.605,
      low: 187.481,
      high: 187.605,
      close: 187.5,
      volume: 950
    },
    {
      date: "2021-10-19 12:05:00",
      open: 187.2975,
      low: 187.25,
      high: 187.67,
      close: 187.5753,
      volume: 1865
    },
    {
      date: "2021-10-19 12:04:00",
      open: 187.86,
      low: 187.3363,
      high: 187.86,
      close: 187.6033,
      volume: 3204
    },
    {
      date: "2021-10-19 12:03:00",
      open: 188.105,
      low: 187.97,
      high: 188.105,
      close: 187.985,
      volume: 1915
    },
    {
      date: "2021-10-19 12:02:00",
      open: 188.15,
      low: 188.07,
      high: 188.25,
      close: 188.07,
      volume: 2177
    },
    {
      date: "2021-10-19 12:01:00",
      open: 188.25,
      low: 188.155,
      high: 188.3,
      close: 188.155,
      volume: 1066
    },
    {
      date: "2021-10-19 12:00:00",
      open: 188.2525,
      low: 188.2525,
      high: 188.2525,
      close: 188.2525,
      volume: 624
    },
    {
      date: "2021-10-19 11:59:00",
      open: 188.5,
      low: 188.35,
      high: 188.5,
      close: 188.35,
      volume: 2154
    },
    {
      date: "2021-10-19 11:58:00",
      open: 188.2,
      low: 188.2,
      high: 188.62,
      close: 188.5,
      volume: 5486
    },
    {
      date: "2021-10-19 11:57:00",
      open: 188.56,
      low: 188.56,
      high: 188.56,
      close: 188.56,
      volume: 389
    },
    {
      date: "2021-10-19 11:56:00",
      open: 188.19,
      low: 188.19,
      high: 188.56,
      close: 188.5,
      volume: 7269
    },
    {
      date: "2021-10-19 11:55:00",
      open: 188.41,
      low: 187.87,
      high: 188.41,
      close: 188.19,
      volume: 8039
    },
    {
      date: "2021-10-19 11:54:00",
      open: 188.5,
      low: 188.4301,
      high: 188.6814,
      close: 188.4301,
      volume: 4472
    },
    {
      date: "2021-10-19 11:53:00",
      open: 188.75,
      low: 188.41,
      high: 188.75,
      close: 188.41,
      volume: 1241
    },
    {
      date: "2021-10-19 11:52:00",
      open: 188.3001,
      low: 188.3001,
      high: 188.6239,
      close: 188.6239,
      volume: 626
    },
    {
      date: "2021-10-19 11:51:00",
      open: 188.35,
      low: 188.2,
      high: 188.3648,
      close: 188.32,
      volume: 2031
    },
    {
      date: "2021-10-19 11:50:00",
      open: 188.31,
      low: 188.28,
      high: 188.31,
      close: 188.3,
      volume: 1700
    },
    {
      date: "2021-10-19 11:49:00",
      open: 189.0301,
      low: 188.311,
      high: 189.18,
      close: 188.311,
      volume: 7036
    },
    {
      date: "2021-10-19 11:48:00",
      open: 189.05,
      low: 188.95,
      high: 189.06,
      close: 189.03,
      volume: 989
    },
    {
      date: "2021-10-19 11:47:00",
      open: 189.08,
      low: 189.04,
      high: 189.08,
      close: 189.04,
      volume: 684
    },
    {
      date: "2021-10-19 11:46:00",
      open: 188.8,
      low: 188.8,
      high: 188.88,
      close: 188.865,
      volume: 1407
    },
    {
      date: "2021-10-19 11:45:00",
      open: 189.1,
      low: 188.8946,
      high: 189.1,
      close: 188.8998,
      volume: 1921
    },
    {
      date: "2021-10-19 11:44:00",
      open: 189.08,
      low: 188.8001,
      high: 189.08,
      close: 188.8001,
      volume: 1001
    },
    {
      date: "2021-10-19 11:43:00",
      open: 188.8658,
      low: 188.66,
      high: 188.9579,
      close: 188.9579,
      volume: 910
    },
    {
      date: "2021-10-19 11:42:00",
      open: 188.9,
      low: 188.9,
      high: 188.9,
      close: 188.9,
      volume: 598
    },
    {
      date: "2021-10-19 11:41:00",
      open: 189.2,
      low: 188.74,
      high: 189.26,
      close: 188.75,
      volume: 3673
    },
    {
      date: "2021-10-19 11:40:00",
      open: 188.5137,
      low: 188.5137,
      high: 188.8489,
      close: 188.8489,
      volume: 2017
    },
    {
      date: "2021-10-19 11:39:00",
      open: 188.68,
      low: 188.6,
      high: 188.91,
      close: 188.91,
      volume: 3973
    },
    {
      date: "2021-10-19 11:38:00",
      open: 188.98,
      low: 188.6698,
      high: 189.1,
      close: 188.6928,
      volume: 2485
    },
    {
      date: "2021-10-19 11:37:00",
      open: 188.59,
      low: 188.3662,
      high: 189.39,
      close: 189.095,
      volume: 13660
    },
    {
      date: "2021-10-19 11:36:00",
      open: 188.47,
      low: 188.38,
      high: 188.62,
      close: 188.38,
      volume: 3415
    },
    {
      date: "2021-10-19 11:35:00",
      open: 188.39,
      low: 188.31,
      high: 188.5,
      close: 188.35,
      volume: 4719
    },
    {
      date: "2021-10-19 11:34:00",
      open: 188.09,
      low: 188.09,
      high: 188.39,
      close: 188.1601,
      volume: 1284
    },
    {
      date: "2021-10-19 11:33:00",
      open: 188.0,
      low: 187.99,
      high: 188.21,
      close: 188.21,
      volume: 1307
    },
    {
      date: "2021-10-19 11:32:00",
      open: 187.82,
      low: 187.7101,
      high: 188.0,
      close: 187.7101,
      volume: 978
    },
    {
      date: "2021-10-19 11:31:00",
      open: 188.0,
      low: 187.91,
      high: 188.0,
      close: 187.9193,
      volume: 1324
    },
    {
      date: "2021-10-19 11:30:00",
      open: 188.125,
      low: 188.0,
      high: 188.125,
      close: 188.0,
      volume: 1488
    },
    {
      date: "2021-10-19 11:29:00",
      open: 187.8,
      low: 187.8,
      high: 188.49,
      close: 188.2643,
      volume: 9057
    },
    {
      date: "2021-10-19 11:28:00",
      open: 187.813,
      low: 187.813,
      high: 187.813,
      close: 187.813,
      volume: 677
    },
    {
      date: "2021-10-19 11:27:00",
      open: 187.5,
      low: 187.5,
      high: 187.745,
      close: 187.647,
      volume: 1297
    },
    {
      date: "2021-10-19 11:26:00",
      open: 187.5,
      low: 187.5,
      high: 187.84,
      close: 187.84,
      volume: 1541
    },
    {
      date: "2021-10-19 11:25:00",
      open: 187.6,
      low: 187.0,
      high: 187.6,
      close: 187.59,
      volume: 4739
    },
    {
      date: "2021-10-19 11:24:00",
      open: 187.99,
      low: 187.1501,
      high: 187.99,
      close: 187.38,
      volume: 10844
    },
    {
      date: "2021-10-19 11:23:00",
      open: 188.22,
      low: 187.6902,
      high: 188.27,
      close: 188.27,
      volume: 11658
    },
    {
      date: "2021-10-19 11:22:00",
      open: 188.49,
      low: 188.17,
      high: 188.6299,
      close: 188.47,
      volume: 7224
    },
    {
      date: "2021-10-19 11:21:00",
      open: 188.1141,
      low: 188.028,
      high: 188.4694,
      close: 188.4694,
      volume: 6153
    },
    {
      date: "2021-10-19 11:20:00",
      open: 188.171,
      low: 188.03,
      high: 188.171,
      close: 188.12,
      volume: 2067
    },
    {
      date: "2021-10-19 11:19:00",
      open: 188.2115,
      low: 188.1,
      high: 188.38,
      close: 188.3,
      volume: 3069
    },
    {
      date: "2021-10-19 11:18:00",
      open: 187.83,
      low: 187.83,
      high: 188.67,
      close: 188.38,
      volume: 10971
    },
    {
      date: "2021-10-19 11:17:00",
      open: 187.4,
      low: 187.4,
      high: 187.69,
      close: 187.69,
      volume: 3755
    },
    {
      date: "2021-10-19 11:16:00",
      open: 187.7,
      low: 187.42,
      high: 187.83,
      close: 187.55,
      volume: 4325
    },
    {
      date: "2021-10-19 11:15:00",
      open: 187.17,
      low: 187.17,
      high: 187.63,
      close: 187.4,
      volume: 4076
    },
    {
      date: "2021-10-19 11:14:00",
      open: 187.38,
      low: 187.3,
      high: 187.5,
      close: 187.4288,
      volume: 2373
    },
    {
      date: "2021-10-19 11:13:00",
      open: 186.98,
      low: 186.9458,
      high: 187.32,
      close: 187.3199,
      volume: 3221
    },
    {
      date: "2021-10-19 11:12:00",
      open: 186.84,
      low: 186.655,
      high: 186.94,
      close: 186.835,
      volume: 2125
    },
    {
      date: "2021-10-19 11:11:00",
      open: 187.57,
      low: 186.92,
      high: 187.59,
      close: 186.92,
      volume: 7727
    },
    {
      date: "2021-10-19 11:10:00",
      open: 187.49,
      low: 187.49,
      high: 187.6,
      close: 187.5,
      volume: 2830
    },
    {
      date: "2021-10-19 11:09:00",
      open: 187.25,
      low: 187.25,
      high: 187.4,
      close: 187.4,
      volume: 922
    },
    {
      date: "2021-10-19 11:08:00",
      open: 187.32,
      low: 187.3,
      high: 187.45,
      close: 187.3,
      volume: 1900
    },
    {
      date: "2021-10-19 11:07:00",
      open: 187.05,
      low: 186.89,
      high: 187.58,
      close: 187.24,
      volume: 31244
    },
    {
      date: "2021-10-19 11:06:00",
      open: 186.51,
      low: 186.23,
      high: 187.08,
      close: 187.08,
      volume: 17864
    },
    {
      date: "2021-10-19 11:05:00",
      open: 186.81,
      low: 186.56,
      high: 187.09,
      close: 186.56,
      volume: 3970
    },
    {
      date: "2021-10-19 11:04:00",
      open: 186.91,
      low: 186.71,
      high: 187.02,
      close: 187.02,
      volume: 10545
    },
    {
      date: "2021-10-19 11:03:00",
      open: 187.051,
      low: 186.52,
      high: 187.1,
      close: 186.886,
      volume: 4760
    },
    {
      date: "2021-10-19 11:02:00",
      open: 186.9,
      low: 186.565,
      high: 187.08,
      close: 186.98,
      volume: 14167
    },
    {
      date: "2021-10-19 11:01:00",
      open: 186.78,
      low: 186.2,
      high: 187.01,
      close: 186.84,
      volume: 24664
    },
    {
      date: "2021-10-19 11:00:00",
      open: 185.96,
      low: 185.96,
      high: 187.2,
      close: 187.2,
      volume: 26123
    },
    {
      date: "2021-10-19 10:59:00",
      open: 186.25,
      low: 186.06,
      high: 186.52,
      close: 186.3266,
      volume: 10136
    },
    {
      date: "2021-10-19 10:58:00",
      open: 186.19,
      low: 185.5,
      high: 186.5939,
      close: 186.17,
      volume: 19475
    },
    {
      date: "2021-10-19 10:57:00",
      open: 187.0,
      low: 186.26,
      high: 187.13,
      close: 186.355,
      volume: 29008
    },
    {
      date: "2021-10-19 10:56:00",
      open: 186.9587,
      low: 186.8499,
      high: 186.99,
      close: 186.89,
      volume: 7420
    },
    {
      date: "2021-10-19 10:55:00",
      open: 187.0,
      low: 186.58,
      high: 187.02,
      close: 186.8353,
      volume: 5229
    },
    {
      date: "2021-10-19 10:54:00",
      open: 186.61,
      low: 186.5,
      high: 187.5,
      close: 187.22,
      volume: 80819
    },
    {
      date: "2021-10-19 10:53:00",
      open: 186.51,
      low: 186.3295,
      high: 186.8199,
      close: 186.6,
      volume: 12918
    },
    {
      date: "2021-10-19 10:52:00",
      open: 185.96,
      low: 185.96,
      high: 186.9,
      close: 186.72,
      volume: 13278
    },
    {
      date: "2021-10-19 10:51:00",
      open: 185.92,
      low: 185.7001,
      high: 185.9606,
      close: 185.7001,
      volume: 5267
    },
    {
      date: "2021-10-19 10:50:00",
      open: 186.3914,
      low: 185.615,
      high: 186.3914,
      close: 186.0767,
      volume: 4088
    },
    {
      date: "2021-10-19 10:49:00",
      open: 186.57,
      low: 186.06,
      high: 186.57,
      close: 186.06,
      volume: 2411
    },
    {
      date: "2021-10-19 10:48:00",
      open: 186.62,
      low: 186.59,
      high: 187.12,
      close: 186.85,
      volume: 32728
    },
    {
      date: "2021-10-19 10:47:00",
      open: 185.9,
      low: 185.9,
      high: 186.63,
      close: 186.63,
      volume: 15507
    },
    {
      date: "2021-10-19 10:46:00",
      open: 185.84,
      low: 185.685,
      high: 186.28,
      close: 186.28,
      volume: 2437
    },
    {
      date: "2021-10-19 10:45:00",
      open: 185.685,
      low: 185.51,
      high: 185.69,
      close: 185.69,
      volume: 1938
    },
    {
      date: "2021-10-19 10:44:00",
      open: 185.58,
      low: 185.363,
      high: 185.91,
      close: 185.72,
      volume: 9509
    },
    {
      date: "2021-10-19 10:43:00",
      open: 186.16,
      low: 185.53,
      high: 186.5,
      close: 185.84,
      volume: 17131
    },
    {
      date: "2021-10-19 10:42:00",
      open: 186.07,
      low: 185.74,
      high: 186.17,
      close: 186.16,
      volume: 14113
    },
    {
      date: "2021-10-19 10:41:00",
      open: 185.7194,
      low: 185.658,
      high: 186.38,
      close: 186.05,
      volume: 20170
    },
    {
      date: "2021-10-19 10:40:00",
      open: 185.2332,
      low: 185.2332,
      high: 185.99,
      close: 185.58,
      volume: 10847
    },
    {
      date: "2021-10-19 10:39:00",
      open: 184.8,
      low: 184.8,
      high: 185.42,
      close: 185.42,
      volume: 9995
    },
    {
      date: "2021-10-19 10:38:00",
      open: 185.32,
      low: 184.4,
      high: 185.855,
      close: 185.21,
      volume: 35121
    },
    {
      date: "2021-10-19 10:37:00",
      open: 186.31,
      low: 185.3136,
      high: 186.46,
      close: 185.3136,
      volume: 35033
    },
    {
      date: "2021-10-19 10:36:00",
      open: 186.82,
      low: 186.2,
      high: 186.82,
      close: 186.31,
      volume: 14189
    },
    {
      date: "2021-10-19 10:35:00",
      open: 186.97,
      low: 186.825,
      high: 186.99,
      close: 186.85,
      volume: 1937
    },
    {
      date: "2021-10-19 10:34:00",
      open: 187.0103,
      low: 186.7,
      high: 187.12,
      close: 186.7,
      volume: 3603
    },
    {
      date: "2021-10-19 10:33:00",
      open: 187.0,
      low: 186.95,
      high: 187.035,
      close: 187.035,
      volume: 1314
    },
    {
      date: "2021-10-19 10:32:00",
      open: 187.0,
      low: 187.0,
      high: 187.15,
      close: 187.15,
      volume: 1852
    },
    {
      date: "2021-10-19 10:31:00",
      open: 186.8,
      low: 186.5783,
      high: 186.997,
      close: 186.99,
      volume: 6232
    },
    {
      date: "2021-10-19 10:30:00",
      open: 187.4225,
      low: 186.38,
      high: 187.4225,
      close: 186.74,
      volume: 8659
    },
    {
      date: "2021-10-19 10:29:00",
      open: 187.89,
      low: 187.32,
      high: 188.0,
      close: 187.39,
      volume: 7816
    },
    {
      date: "2021-10-19 10:28:00",
      open: 187.55,
      low: 187.43,
      high: 187.75,
      close: 187.75,
      volume: 7332
    },
    {
      date: "2021-10-19 10:27:00",
      open: 187.385,
      low: 187.35,
      high: 187.4664,
      close: 187.35,
      volume: 824
    },
    {
      date: "2021-10-19 10:26:00",
      open: 186.95,
      low: 186.95,
      high: 187.3244,
      close: 187.3244,
      volume: 2330
    },
    {
      date: "2021-10-19 10:24:00",
      open: 187.0,
      low: 187.0,
      high: 187.23,
      close: 187.23,
      volume: 2325
    },
    {
      date: "2021-10-19 10:23:00",
      open: 187.23,
      low: 187.0,
      high: 187.23,
      close: 187.0,
      volume: 1580
    },
    {
      date: "2021-10-19 10:22:00",
      open: 187.37,
      low: 187.16,
      high: 187.4,
      close: 187.23,
      volume: 2256
    },
    {
      date: "2021-10-19 10:21:00",
      open: 186.9733,
      low: 186.9733,
      high: 187.41,
      close: 187.41,
      volume: 5739
    },
    {
      date: "2021-10-19 10:20:00",
      open: 186.93,
      low: 186.93,
      high: 187.2,
      close: 187.02,
      volume: 4062
    },
    {
      date: "2021-10-19 10:19:00",
      open: 187.38,
      low: 186.75,
      high: 187.7299,
      close: 186.81,
      volume: 5785
    },
    {
      date: "2021-10-19 10:18:00",
      open: 187.23,
      low: 187.176,
      high: 187.43,
      close: 187.176,
      volume: 2618
    },
    {
      date: "2021-10-19 10:17:00",
      open: 187.16,
      low: 187.0,
      high: 187.527,
      close: 187.3779,
      volume: 8908
    },
    {
      date: "2021-10-19 10:16:00",
      open: 186.8888,
      low: 186.8888,
      high: 187.13,
      close: 187.08,
      volume: 5150
    },
    {
      date: "2021-10-19 10:15:00",
      open: 186.47,
      low: 186.47,
      high: 186.9,
      close: 186.81,
      volume: 2686
    },
    {
      date: "2021-10-19 10:14:00",
      open: 186.5,
      low: 186.36,
      high: 186.8,
      close: 186.45,
      volume: 8004
    },
    {
      date: "2021-10-19 10:13:00",
      open: 186.4707,
      low: 186.4707,
      high: 186.97,
      close: 186.71,
      volume: 5133
    },
    {
      date: "2021-10-19 10:12:00",
      open: 186.83,
      low: 186.45,
      high: 187.0,
      close: 186.45,
      volume: 9110
    },
    {
      date: "2021-10-19 10:11:00",
      open: 187.23,
      low: 186.8,
      high: 187.48,
      close: 186.9,
      volume: 5590
    },
    {
      date: "2021-10-19 10:10:00",
      open: 187.17,
      low: 186.9,
      high: 187.5499,
      close: 187.36,
      volume: 4942
    },
    {
      date: "2021-10-19 10:09:00",
      open: 187.06,
      low: 186.8,
      high: 187.06,
      close: 187.0,
      volume: 5419
    },
    {
      date: "2021-10-19 10:08:00",
      open: 186.8,
      low: 186.5,
      high: 187.0,
      close: 186.875,
      volume: 9937
    },
    {
      date: "2021-10-19 10:07:00",
      open: 186.5,
      low: 186.5,
      high: 186.83,
      close: 186.7286,
      volume: 5381
    },
    {
      date: "2021-10-19 10:06:00",
      open: 187.71,
      low: 186.21,
      high: 187.71,
      close: 186.8199,
      volume: 24362
    },
    {
      date: "2021-10-19 10:05:00",
      open: 188.0399,
      low: 187.6711,
      high: 188.0399,
      close: 187.865,
      volume: 4920
    },
    {
      date: "2021-10-19 10:04:00",
      open: 188.5379,
      low: 187.85,
      high: 188.5379,
      close: 188.12,
      volume: 4083
    },
    {
      date: "2021-10-19 10:03:00",
      open: 188.2,
      low: 188.0301,
      high: 188.62,
      close: 188.1083,
      volume: 4244
    },
    {
      date: "2021-10-19 10:02:00",
      open: 188.1,
      low: 187.5,
      high: 188.295,
      close: 188.225,
      volume: 7094
    },
    {
      date: "2021-10-19 10:01:00",
      open: 187.785,
      low: 187.6001,
      high: 188.29,
      close: 187.81,
      volume: 3686
    },
    {
      date: "2021-10-19 10:00:00",
      open: 188.2701,
      low: 187.61,
      high: 188.62,
      close: 187.77,
      volume: 11259
    },
    {
      date: "2021-10-19 09:59:00",
      open: 188.91,
      low: 188.21,
      high: 188.97,
      close: 188.34,
      volume: 8819
    },
    {
      date: "2021-10-19 09:58:00",
      open: 188.93,
      low: 188.7246,
      high: 189.21,
      close: 188.845,
      volume: 6261
    },
    {
      date: "2021-10-19 09:57:00",
      open: 189.0,
      low: 188.5973,
      high: 189.12,
      close: 188.93,
      volume: 7578
    },
    {
      date: "2021-10-19 09:56:00",
      open: 190.205,
      low: 189.0,
      high: 190.205,
      close: 189.0,
      volume: 8335
    },
    {
      date: "2021-10-19 09:55:00",
      open: 189.82,
      low: 189.81,
      high: 190.7279,
      close: 190.17,
      volume: 7313
    },
    {
      date: "2021-10-19 09:54:00",
      open: 189.32,
      low: 189.26,
      high: 190.1101,
      close: 189.83,
      volume: 7453
    },
    {
      date: "2021-10-19 09:53:00",
      open: 189.05,
      low: 188.75,
      high: 189.33,
      close: 189.22,
      volume: 6558
    },
    {
      date: "2021-10-19 09:52:00",
      open: 188.76,
      low: 188.76,
      high: 189.4083,
      close: 189.185,
      volume: 2449
    },
    {
      date: "2021-10-19 09:51:00",
      open: 189.845,
      low: 188.72,
      high: 189.8803,
      close: 188.93,
      volume: 15039
    },
    {
      date: "2021-10-19 09:50:00",
      open: 190.0,
      low: 189.7,
      high: 190.09,
      close: 189.81,
      volume: 6473
    },
    {
      date: "2021-10-19 09:49:00",
      open: 190.2705,
      low: 189.67,
      high: 190.2705,
      close: 189.9,
      volume: 9931
    },
    {
      date: "2021-10-19 09:48:00",
      open: 190.79,
      low: 189.83,
      high: 191.16,
      close: 189.985,
      volume: 14309
    },
    {
      date: "2021-10-19 09:47:00",
      open: 190.93,
      low: 190.51,
      high: 190.93,
      close: 190.75,
      volume: 5681
    },
    {
      date: "2021-10-19 09:46:00",
      open: 190.81,
      low: 190.5909,
      high: 190.9898,
      close: 190.95,
      volume: 5450
    },
    {
      date: "2021-10-19 09:45:00",
      open: 191.1,
      low: 190.59,
      high: 191.1958,
      close: 190.73,
      volume: 10614
    },
    {
      date: "2021-10-19 09:44:00",
      open: 191.2031,
      low: 190.87,
      high: 191.26,
      close: 191.1,
      volume: 13619
    },
    {
      date: "2021-10-19 09:43:00",
      open: 191.61,
      low: 191.1701,
      high: 191.79,
      close: 191.4032,
      volume: 18013
    },
    {
      date: "2021-10-19 09:42:00",
      open: 191.25,
      low: 191.19,
      high: 192.18,
      close: 191.61,
      volume: 44637
    },
    {
      date: "2021-10-19 09:41:00",
      open: 190.55,
      low: 190.4877,
      high: 191.489,
      close: 191.26,
      volume: 25616
    },
    {
      date: "2021-10-19 09:40:00",
      open: 190.54,
      low: 190.08,
      high: 190.58,
      close: 190.55,
      volume: 12799
    },
    {
      date: "2021-10-19 09:39:00",
      open: 190.37,
      low: 190.13,
      high: 190.67,
      close: 190.67,
      volume: 8061
    },
    {
      date: "2021-10-19 09:38:00",
      open: 191.35,
      low: 190.23,
      high: 191.43,
      close: 190.37,
      volume: 16600
    },
    {
      date: "2021-10-19 09:37:00",
      open: 190.7405,
      low: 190.5501,
      high: 191.43,
      close: 191.09,
      volume: 24364
    },
    {
      date: "2021-10-19 09:36:00",
      open: 190.05,
      low: 190.05,
      high: 191.0587,
      close: 190.6668,
      volume: 24400
    },
    {
      date: "2021-10-19 09:35:00",
      open: 190.1,
      low: 189.5205,
      high: 190.48,
      close: 190.17,
      volume: 16787
    },
    {
      date: "2021-10-19 09:34:00",
      open: 190.71,
      low: 190.06,
      high: 190.79,
      close: 190.2,
      volume: 12676
    },
    {
      date: "2021-10-19 09:33:00",
      open: 190.98,
      low: 190.131,
      high: 191.84,
      close: 190.64,
      volume: 36476
    },
    {
      date: "2021-10-19 09:32:00",
      open: 188.19,
      low: 188.17,
      high: 191.02,
      close: 191.01,
      volume: 89625
    },
    {
      date: "2021-10-19 09:31:00",
      open: 187.3,
      low: 187.3,
      high: 188.98,
      close: 188.12,
      volume: 11284
    },
    {
      date: "2021-10-19 09:30:00",
      open: 188.99,
      low: 187.36,
      high: 189.19,
      close: 187.36,
      volume: 44398
    },
    {
      date: "2021-10-18 15:59:00",
      open: 185.88,
      low: 185.7401,
      high: 185.94,
      close: 185.94,
      volume: 19680
    },
    {
      date: "2021-10-18 15:58:00",
      open: 185.9,
      low: 185.8,
      high: 185.94,
      close: 185.87,
      volume: 11237
    },
    {
      date: "2021-10-18 15:57:00",
      open: 185.92,
      low: 185.8,
      high: 186.0,
      close: 185.92,
      volume: 4810
    },
    {
      date: "2021-10-18 15:56:00",
      open: 186.045,
      low: 185.86,
      high: 186.045,
      close: 185.885,
      volume: 5048
    },
    {
      date: "2021-10-18 15:55:00",
      open: 186.04,
      low: 185.81,
      high: 186.19,
      close: 186.105,
      volume: 7346
    },
    {
      date: "2021-10-18 15:54:00",
      open: 186.355,
      low: 186.12,
      high: 186.5,
      close: 186.2,
      volume: 14301
    },
    {
      date: "2021-10-18 15:53:00",
      open: 186.3,
      low: 186.08,
      high: 186.35,
      close: 186.35,
      volume: 5117
    },
    {
      date: "2021-10-18 15:52:00",
      open: 186.26,
      low: 186.16,
      high: 186.394,
      close: 186.16,
      volume: 3665
    },
    {
      date: "2021-10-18 15:51:00",
      open: 186.04,
      low: 186.04,
      high: 186.33,
      close: 186.075,
      volume: 7920
    },
    {
      date: "2021-10-18 15:50:00",
      open: 185.45,
      low: 185.44,
      high: 186.15,
      close: 185.98,
      volume: 11756
    },
    {
      date: "2021-10-18 15:49:00",
      open: 185.18,
      low: 185.09,
      high: 185.2434,
      close: 185.14,
      volume: 2557
    },
    {
      date: "2021-10-18 15:48:00",
      open: 185.21,
      low: 185.21,
      high: 185.25,
      close: 185.25,
      volume: 1531
    },
    {
      date: "2021-10-18 15:47:00",
      open: 185.2,
      low: 185.041,
      high: 185.35,
      close: 185.2,
      volume: 4804
    },
    {
      date: "2021-10-18 15:46:00",
      open: 185.2,
      low: 185.2,
      high: 185.41,
      close: 185.36,
      volume: 3153
    },
    {
      date: "2021-10-18 15:45:00",
      open: 185.26,
      low: 185.26,
      high: 185.53,
      close: 185.333,
      volume: 2257
    },
    {
      date: "2021-10-18 15:44:00",
      open: 185.3664,
      low: 185.2218,
      high: 185.3664,
      close: 185.33,
      volume: 2826
    },
    {
      date: "2021-10-18 15:43:00",
      open: 185.5666,
      low: 185.4,
      high: 185.6,
      close: 185.4,
      volume: 3351
    },
    {
      date: "2021-10-18 15:42:00",
      open: 185.88,
      low: 185.65,
      high: 185.88,
      close: 185.735,
      volume: 2747
    },
    {
      date: "2021-10-18 15:41:00",
      open: 186.01,
      low: 186.0,
      high: 186.03,
      close: 186.03,
      volume: 2072
    },
    {
      date: "2021-10-18 15:40:00",
      open: 185.86,
      low: 185.8,
      high: 186.12,
      close: 186.12,
      volume: 4720
    },
    {
      date: "2021-10-18 15:39:00",
      open: 185.75,
      low: 185.74,
      high: 185.98,
      close: 185.86,
      volume: 2849
    },
    {
      date: "2021-10-18 15:38:00",
      open: 185.59,
      low: 185.59,
      high: 185.715,
      close: 185.66,
      volume: 1395
    },
    {
      date: "2021-10-18 15:37:00",
      open: 185.6831,
      low: 185.67,
      high: 185.6831,
      close: 185.67,
      volume: 888
    },
    {
      date: "2021-10-18 15:36:00",
      open: 185.58,
      low: 185.4011,
      high: 185.7093,
      close: 185.66,
      volume: 4443
    },
    {
      date: "2021-10-18 15:34:00",
      open: 185.61,
      low: 185.61,
      high: 185.61,
      close: 185.61,
      volume: 895
    },
    {
      date: "2021-10-18 15:33:00",
      open: 185.705,
      low: 185.705,
      high: 185.8373,
      close: 185.78,
      volume: 1642
    },
    {
      date: "2021-10-18 15:32:00",
      open: 185.71,
      low: 185.71,
      high: 185.84,
      close: 185.76,
      volume: 1045
    },
    {
      date: "2021-10-18 15:31:00",
      open: 185.79,
      low: 185.71,
      high: 185.79,
      close: 185.71,
      volume: 652
    },
    {
      date: "2021-10-18 15:30:00",
      open: 186.0334,
      low: 185.825,
      high: 186.0334,
      close: 185.825,
      volume: 1103
    },
    {
      date: "2021-10-18 15:29:00",
      open: 185.72,
      low: 185.72,
      high: 186.03,
      close: 186.03,
      volume: 2311
    },
    {
      date: "2021-10-18 15:28:00",
      open: 186.01,
      low: 185.74,
      high: 186.01,
      close: 185.845,
      volume: 2776
    },
    {
      date: "2021-10-18 15:27:00",
      open: 186.0086,
      low: 186.0086,
      high: 186.0086,
      close: 186.0086,
      volume: 1154
    },
    {
      date: "2021-10-18 15:26:00",
      open: 186.0178,
      low: 186.0178,
      high: 186.0178,
      close: 186.0178,
      volume: 460
    },
    {
      date: "2021-10-18 15:25:00",
      open: 185.865,
      low: 185.865,
      high: 185.96,
      close: 185.96,
      volume: 406
    },
    {
      date: "2021-10-18 15:24:00",
      open: 185.89,
      low: 185.78,
      high: 185.8999,
      close: 185.8999,
      volume: 1864
    },
    {
      date: "2021-10-18 15:23:00",
      open: 186.063,
      low: 185.91,
      high: 186.0654,
      close: 185.91,
      volume: 1298
    },
    {
      date: "2021-10-18 15:22:00",
      open: 186.265,
      low: 186.045,
      high: 186.265,
      close: 186.0924,
      volume: 8285
    },
    {
      date: "2021-10-18 15:21:00",
      open: 186.48,
      low: 186.39,
      high: 186.52,
      close: 186.42,
      volume: 2937
    },
    {
      date: "2021-10-18 15:20:00",
      open: 185.9743,
      low: 185.9743,
      high: 186.4799,
      close: 186.4799,
      volume: 11488
    },
    {
      date: "2021-10-18 15:19:00",
      open: 186.01,
      low: 185.95,
      high: 186.13,
      close: 185.98,
      volume: 1304
    },
    {
      date: "2021-10-18 15:18:00",
      open: 185.924,
      low: 185.924,
      high: 186.02,
      close: 186.02,
      volume: 816
    },
    {
      date: "2021-10-18 15:17:00",
      open: 186.18,
      low: 186.0,
      high: 186.18,
      close: 186.06,
      volume: 2705
    },
    {
      date: "2021-10-18 15:16:00",
      open: 186.1764,
      low: 186.1764,
      high: 186.26,
      close: 186.26,
      volume: 1029
    },
    {
      date: "2021-10-18 15:15:00",
      open: 186.16,
      low: 186.107,
      high: 186.2,
      close: 186.14,
      volume: 3124
    },
    {
      date: "2021-10-18 15:14:00",
      open: 186.3284,
      low: 186.3284,
      high: 186.3284,
      close: 186.3284,
      volume: 660
    },
    {
      date: "2021-10-18 15:13:00",
      open: 186.28,
      low: 186.28,
      high: 186.28,
      close: 186.28,
      volume: 670
    },
    {
      date: "2021-10-18 15:12:00",
      open: 186.3044,
      low: 186.23,
      high: 186.3044,
      close: 186.23,
      volume: 939
    },
    {
      date: "2021-10-18 15:11:00",
      open: 186.39,
      low: 186.3,
      high: 186.39,
      close: 186.31,
      volume: 1624
    },
    {
      date: "2021-10-18 15:10:00",
      open: 186.26,
      low: 186.26,
      high: 186.45,
      close: 186.45,
      volume: 1058
    },
    {
      date: "2021-10-18 15:09:00",
      open: 186.0378,
      low: 186.0378,
      high: 186.2,
      close: 186.2,
      volume: 1903
    },
    {
      date: "2021-10-18 15:08:00",
      open: 186.27,
      low: 186.27,
      high: 186.285,
      close: 186.285,
      volume: 600
    },
    {
      date: "2021-10-18 15:07:00",
      open: 186.27,
      low: 186.27,
      high: 186.28,
      close: 186.28,
      volume: 535
    },
    {
      date: "2021-10-18 15:06:00",
      open: 186.25,
      low: 186.03,
      high: 186.33,
      close: 186.33,
      volume: 4129
    },
    {
      date: "2021-10-18 15:05:00",
      open: 186.75,
      low: 186.4,
      high: 186.75,
      close: 186.42,
      volume: 5020
    },
    {
      date: "2021-10-18 15:04:00",
      open: 186.51,
      low: 186.45,
      high: 186.58,
      close: 186.58,
      volume: 1768
    },
    {
      date: "2021-10-18 15:02:00",
      open: 186.93,
      low: 186.71,
      high: 186.93,
      close: 186.9,
      volume: 2173
    },
    {
      date: "2021-10-18 15:01:00",
      open: 187.24,
      low: 187.23,
      high: 187.335,
      close: 187.25,
      volume: 1987
    },
    {
      date: "2021-10-18 15:00:00",
      open: 186.82,
      low: 186.82,
      high: 187.19,
      close: 187.0,
      volume: 3383
    },
    {
      date: "2021-10-18 14:59:00",
      open: 186.68,
      low: 186.57,
      high: 186.82,
      close: 186.82,
      volume: 2306
    },
    {
      date: "2021-10-18 14:57:00",
      open: 186.6,
      low: 186.6,
      high: 186.6,
      close: 186.6,
      volume: 367
    },
    {
      date: "2021-10-18 14:56:00",
      open: 186.43,
      low: 186.43,
      high: 186.6293,
      close: 186.505,
      volume: 1286
    },
    {
      date: "2021-10-18 14:55:00",
      open: 186.45,
      low: 186.43,
      high: 186.56,
      close: 186.56,
      volume: 1477
    },
    {
      date: "2021-10-18 14:54:00",
      open: 186.7,
      low: 186.45,
      high: 186.7,
      close: 186.52,
      volume: 1284
    },
    {
      date: "2021-10-18 14:53:00",
      open: 186.71,
      low: 186.62,
      high: 186.71,
      close: 186.62,
      volume: 446
    },
    {
      date: "2021-10-18 14:52:00",
      open: 186.5455,
      low: 186.5455,
      high: 186.5455,
      close: 186.5455,
      volume: 395
    },
    {
      date: "2021-10-18 14:51:00",
      open: 186.33,
      low: 186.33,
      high: 186.54,
      close: 186.54,
      volume: 1598
    },
    {
      date: "2021-10-18 14:50:00",
      open: 186.53,
      low: 186.358,
      high: 186.53,
      close: 186.358,
      volume: 654
    },
    {
      date: "2021-10-18 14:49:00",
      open: 186.41,
      low: 186.41,
      high: 186.41,
      close: 186.41,
      volume: 458
    },
    {
      date: "2021-10-18 14:48:00",
      open: 186.412,
      low: 186.39,
      high: 186.5066,
      close: 186.5066,
      volume: 865
    },
    {
      date: "2021-10-18 14:47:00",
      open: 186.3,
      low: 186.3,
      high: 186.513,
      close: 186.46,
      volume: 1001
    },
    {
      date: "2021-10-18 14:46:00",
      open: 186.5543,
      low: 186.23,
      high: 186.5543,
      close: 186.3036,
      volume: 1629
    },
    {
      date: "2021-10-18 14:45:00",
      open: 186.48,
      low: 186.46,
      high: 186.59,
      close: 186.59,
      volume: 802
    },
    {
      date: "2021-10-18 14:44:00",
      open: 186.58,
      low: 186.46,
      high: 186.7136,
      close: 186.565,
      volume: 3445
    },
    {
      date: "2021-10-18 14:43:00",
      open: 186.685,
      low: 186.685,
      high: 186.82,
      close: 186.75,
      volume: 1489
    },
    {
      date: "2021-10-18 14:42:00",
      open: 186.88,
      low: 186.88,
      high: 186.88,
      close: 186.88,
      volume: 441
    },
    {
      date: "2021-10-18 14:39:00",
      open: 186.97,
      low: 186.7,
      high: 186.97,
      close: 186.9309,
      volume: 4590
    },
    {
      date: "2021-10-18 14:38:00",
      open: 187.315,
      low: 186.78,
      high: 187.315,
      close: 187.12,
      volume: 6088
    },
    {
      date: "2021-10-18 14:37:00",
      open: 187.3084,
      low: 187.18,
      high: 187.3084,
      close: 187.18,
      volume: 1586
    },
    {
      date: "2021-10-18 14:36:00",
      open: 187.08,
      low: 187.08,
      high: 187.32,
      close: 187.27,
      volume: 1935
    },
    {
      date: "2021-10-18 14:35:00",
      open: 186.8677,
      low: 186.61,
      high: 186.91,
      close: 186.91,
      volume: 1990
    },
    {
      date: "2021-10-18 14:34:00",
      open: 186.93,
      low: 186.9,
      high: 186.93,
      close: 186.9,
      volume: 1131
    },
    {
      date: "2021-10-18 14:33:00",
      open: 187.02,
      low: 186.821,
      high: 187.04,
      close: 186.89,
      volume: 4286
    },
    {
      date: "2021-10-18 14:32:00",
      open: 187.25,
      low: 187.12,
      high: 187.32,
      close: 187.26,
      volume: 2002
    },
    {
      date: "2021-10-18 14:31:00",
      open: 187.1266,
      low: 187.1266,
      high: 187.3,
      close: 187.3,
      volume: 1000
    },
    {
      date: "2021-10-18 14:30:00",
      open: 187.36,
      low: 187.32,
      high: 187.4456,
      close: 187.4456,
      volume: 1908
    },
    {
      date: "2021-10-18 14:29:00",
      open: 187.498,
      low: 187.2701,
      high: 187.57,
      close: 187.515,
      volume: 2390
    },
    {
      date: "2021-10-18 14:28:00",
      open: 187.03,
      low: 187.03,
      high: 187.56,
      close: 187.56,
      volume: 5587
    },
    {
      date: "2021-10-18 14:27:00",
      open: 186.45,
      low: 186.45,
      high: 187.03,
      close: 187.03,
      volume: 4006
    },
    {
      date: "2021-10-18 14:26:00",
      open: 186.677,
      low: 186.48,
      high: 186.677,
      close: 186.5997,
      volume: 533
    },
    {
      date: "2021-10-18 14:25:00",
      open: 186.69,
      low: 186.68,
      high: 186.7,
      close: 186.6985,
      volume: 2019
    },
    {
      date: "2021-10-18 14:24:00",
      open: 186.65,
      low: 186.55,
      high: 186.7,
      close: 186.6999,
      volume: 102706
    },
    {
      date: "2021-10-18 14:23:00",
      open: 186.53,
      low: 186.45,
      high: 186.63,
      close: 186.63,
      volume: 4098
    },
    {
      date: "2021-10-18 14:22:00",
      open: 186.35,
      low: 186.2714,
      high: 186.7,
      close: 186.7,
      volume: 1515
    },
    {
      date: "2021-10-18 14:21:00",
      open: 186.3,
      low: 186.22,
      high: 186.45,
      close: 186.22,
      volume: 3636
    },
    {
      date: "2021-10-18 14:20:00",
      open: 186.24,
      low: 186.24,
      high: 186.24,
      close: 186.24,
      volume: 510
    },
    {
      date: "2021-10-18 14:19:00",
      open: 186.17,
      low: 186.17,
      high: 186.17,
      close: 186.17,
      volume: 304
    },
    {
      date: "2021-10-18 14:18:00",
      open: 186.0,
      low: 186.0,
      high: 186.1695,
      close: 186.1695,
      volume: 919
    },
    {
      date: "2021-10-18 14:15:00",
      open: 185.9,
      low: 185.8642,
      high: 186.0,
      close: 185.8642,
      volume: 1381
    },
    {
      date: "2021-10-18 14:14:00",
      open: 185.91,
      low: 185.8201,
      high: 185.91,
      close: 185.8201,
      volume: 913
    },
    {
      date: "2021-10-18 14:13:00",
      open: 185.82,
      low: 185.7209,
      high: 185.94,
      close: 185.93,
      volume: 1206
    },
    {
      date: "2021-10-18 14:12:00",
      open: 186.09,
      low: 185.69,
      high: 186.09,
      close: 185.69,
      volume: 2152
    },
    {
      date: "2021-10-18 14:11:00",
      open: 186.53,
      low: 186.08,
      high: 186.53,
      close: 186.08,
      volume: 2691
    },
    {
      date: "2021-10-18 14:10:00",
      open: 186.52,
      low: 186.5,
      high: 186.68,
      close: 186.5,
      volume: 896
    },
    {
      date: "2021-10-18 14:09:00",
      open: 186.49,
      low: 186.47,
      high: 186.54,
      close: 186.52,
      volume: 896
    },
    {
      date: "2021-10-18 14:08:00",
      open: 186.6,
      low: 186.6,
      high: 186.6,
      close: 186.6,
      volume: 242
    },
    {
      date: "2021-10-18 14:07:00",
      open: 186.17,
      low: 186.17,
      high: 186.55,
      close: 186.55,
      volume: 1418
    },
    {
      date: "2021-10-18 14:06:00",
      open: 186.57,
      low: 186.29,
      high: 186.57,
      close: 186.29,
      volume: 1110
    },
    {
      date: "2021-10-18 14:05:00",
      open: 186.53,
      low: 186.53,
      high: 186.58,
      close: 186.58,
      volume: 890
    },
    {
      date: "2021-10-18 14:04:00",
      open: 186.52,
      low: 186.52,
      high: 186.52,
      close: 186.52,
      volume: 751
    },
    {
      date: "2021-10-18 14:03:00",
      open: 186.89,
      low: 186.49,
      high: 186.89,
      close: 186.515,
      volume: 4470
    },
    {
      date: "2021-10-18 14:02:00",
      open: 186.7661,
      low: 186.7661,
      high: 186.7661,
      close: 186.7661,
      volume: 425
    },
    {
      date: "2021-10-18 14:01:00",
      open: 187.07,
      low: 187.04,
      high: 187.07,
      close: 187.04,
      volume: 1537
    },
    {
      date: "2021-10-18 13:59:00",
      open: 187.22,
      low: 187.17,
      high: 187.295,
      close: 187.2,
      volume: 3469
    },
    {
      date: "2021-10-18 13:58:00",
      open: 187.25,
      low: 186.95,
      high: 187.25,
      close: 187.13,
      volume: 2392
    },
    {
      date: "2021-10-18 13:57:00",
      open: 187.2328,
      low: 187.2328,
      high: 187.24,
      close: 187.24,
      volume: 600
    },
    {
      date: "2021-10-18 13:56:00",
      open: 187.335,
      low: 187.335,
      high: 187.36,
      close: 187.36,
      volume: 776
    },
    {
      date: "2021-10-18 13:55:00",
      open: 187.27,
      low: 187.27,
      high: 187.41,
      close: 187.41,
      volume: 1569
    },
    {
      date: "2021-10-18 13:54:00",
      open: 187.45,
      low: 187.4,
      high: 187.45,
      close: 187.43,
      volume: 2467
    },
    {
      date: "2021-10-18 13:53:00",
      open: 187.615,
      low: 187.5,
      high: 187.615,
      close: 187.58,
      volume: 1119
    },
    {
      date: "2021-10-18 13:52:00",
      open: 187.41,
      low: 187.405,
      high: 187.63,
      close: 187.565,
      volume: 3422
    },
    {
      date: "2021-10-18 13:51:00",
      open: 187.4,
      low: 187.4,
      high: 187.545,
      close: 187.545,
      volume: 3340
    },
    {
      date: "2021-10-18 13:50:00",
      open: 187.27,
      low: 187.2472,
      high: 187.42,
      close: 187.42,
      volume: 1512
    },
    {
      date: "2021-10-18 13:49:00",
      open: 187.6,
      low: 187.38,
      high: 187.6,
      close: 187.38,
      volume: 1871
    },
    {
      date: "2021-10-18 13:48:00",
      open: 187.51,
      low: 187.39,
      high: 187.59,
      close: 187.49,
      volume: 3037
    },
    {
      date: "2021-10-18 13:47:00",
      open: 187.32,
      low: 187.3,
      high: 187.6,
      close: 187.51,
      volume: 5798
    },
    {
      date: "2021-10-18 13:46:00",
      open: 187.05,
      low: 187.05,
      high: 187.18,
      close: 187.18,
      volume: 1851
    },
    {
      date: "2021-10-18 13:45:00",
      open: 186.79,
      low: 186.79,
      high: 186.79,
      close: 186.79,
      volume: 539
    },
    {
      date: "2021-10-18 13:44:00",
      open: 187.029,
      low: 187.029,
      high: 187.029,
      close: 187.029,
      volume: 797
    },
    {
      date: "2021-10-18 13:43:00",
      open: 186.95,
      low: 186.92,
      high: 186.95,
      close: 186.92,
      volume: 586
    },
    {
      date: "2021-10-18 13:42:00",
      open: 187.01,
      low: 187.01,
      high: 187.01,
      close: 187.01,
      volume: 746
    },
    {
      date: "2021-10-18 13:41:00",
      open: 187.0027,
      low: 186.87,
      high: 187.0027,
      close: 186.915,
      volume: 716
    },
    {
      date: "2021-10-18 13:40:00",
      open: 187.09,
      low: 186.88,
      high: 187.09,
      close: 186.88,
      volume: 2103
    },
    {
      date: "2021-10-18 13:39:00",
      open: 186.9944,
      low: 186.9944,
      high: 187.2,
      close: 187.18,
      volume: 691
    },
    {
      date: "2021-10-18 13:38:00",
      open: 187.2186,
      low: 187.2099,
      high: 187.2186,
      close: 187.2099,
      volume: 842
    },
    {
      date: "2021-10-18 13:37:00",
      open: 187.15,
      low: 187.05,
      high: 187.15,
      close: 187.05,
      volume: 1993
    },
    {
      date: "2021-10-18 13:35:00",
      open: 187.0,
      low: 186.95,
      high: 187.0,
      close: 186.95,
      volume: 1944
    },
    {
      date: "2021-10-18 13:34:00",
      open: 186.825,
      low: 186.825,
      high: 186.94,
      close: 186.8901,
      volume: 2121
    },
    {
      date: "2021-10-18 13:33:00",
      open: 186.81,
      low: 186.81,
      high: 186.81,
      close: 186.81,
      volume: 448
    },
    {
      date: "2021-10-18 13:32:00",
      open: 186.8,
      low: 186.8,
      high: 186.815,
      close: 186.815,
      volume: 1133
    },
    {
      date: "2021-10-18 13:31:00",
      open: 186.64,
      low: 186.64,
      high: 186.78,
      close: 186.75,
      volume: 2196
    },
    {
      date: "2021-10-18 13:30:00",
      open: 186.55,
      low: 186.4246,
      high: 186.63,
      close: 186.5355,
      volume: 1316
    },
    {
      date: "2021-10-18 13:29:00",
      open: 186.535,
      low: 186.41,
      high: 186.535,
      close: 186.4357,
      volume: 877
    },
    {
      date: "2021-10-18 13:28:00",
      open: 186.58,
      low: 186.58,
      high: 186.58,
      close: 186.58,
      volume: 283
    },
    {
      date: "2021-10-18 13:27:00",
      open: 186.69,
      low: 186.58,
      high: 186.7,
      close: 186.58,
      volume: 1156
    },
    {
      date: "2021-10-18 13:26:00",
      open: 186.51,
      low: 186.51,
      high: 186.6999,
      close: 186.69,
      volume: 824
    },
    {
      date: "2021-10-18 13:25:00",
      open: 186.28,
      low: 186.28,
      high: 186.56,
      close: 186.4,
      volume: 1357
    },
    {
      date: "2021-10-18 13:24:00",
      open: 186.53,
      low: 186.53,
      high: 186.53,
      close: 186.53,
      volume: 783
    },
    {
      date: "2021-10-18 13:23:00",
      open: 186.72,
      low: 186.54,
      high: 186.72,
      close: 186.6,
      volume: 1458
    },
    {
      date: "2021-10-18 13:22:00",
      open: 186.77,
      low: 186.57,
      high: 186.77,
      close: 186.57,
      volume: 3368
    },
    {
      date: "2021-10-18 13:21:00",
      open: 186.9,
      low: 186.7809,
      high: 186.9,
      close: 186.7809,
      volume: 893
    },
    {
      date: "2021-10-18 13:20:00",
      open: 186.75,
      low: 186.65,
      high: 186.77,
      close: 186.76,
      volume: 1711
    },
    {
      date: "2021-10-18 13:19:00",
      open: 187.07,
      low: 186.76,
      high: 187.1164,
      close: 186.76,
      volume: 2640
    },
    {
      date: "2021-10-18 13:18:00",
      open: 186.7594,
      low: 186.6346,
      high: 187.0,
      close: 186.9858,
      volume: 4551
    },
    {
      date: "2021-10-18 13:17:00",
      open: 186.225,
      low: 186.225,
      high: 186.56,
      close: 186.56,
      volume: 2157
    },
    {
      date: "2021-10-18 13:16:00",
      open: 186.142,
      low: 186.04,
      high: 186.3669,
      close: 186.1533,
      volume: 2478
    },
    {
      date: "2021-10-18 13:15:00",
      open: 186.5,
      low: 186.05,
      high: 186.5,
      close: 186.05,
      volume: 3000
    },
    {
      date: "2021-10-18 13:14:00",
      open: 186.5,
      low: 186.5,
      high: 186.56,
      close: 186.55,
      volume: 1168
    },
    {
      date: "2021-10-18 13:13:00",
      open: 186.5,
      low: 186.5,
      high: 186.595,
      close: 186.54,
      volume: 1036
    },
    {
      date: "2021-10-18 13:12:00",
      open: 186.335,
      low: 186.335,
      high: 186.49,
      close: 186.49,
      volume: 1574
    },
    {
      date: "2021-10-18 13:11:00",
      open: 186.35,
      low: 186.275,
      high: 186.4599,
      close: 186.335,
      volume: 1457
    },
    {
      date: "2021-10-18 13:10:00",
      open: 186.327,
      low: 186.2879,
      high: 186.5,
      close: 186.335,
      volume: 5734
    },
    {
      date: "2021-10-18 13:09:00",
      open: 185.98,
      low: 185.98,
      high: 186.22,
      close: 186.22,
      volume: 1958
    },
    {
      date: "2021-10-18 13:08:00",
      open: 186.065,
      low: 185.97,
      high: 186.1,
      close: 186.0436,
      volume: 1333
    },
    {
      date: "2021-10-18 13:07:00",
      open: 185.9199,
      low: 185.918,
      high: 186.0,
      close: 186.0,
      volume: 2983
    },
    {
      date: "2021-10-18 13:06:00",
      open: 185.84,
      low: 185.84,
      high: 185.84,
      close: 185.84,
      volume: 662
    },
    {
      date: "2021-10-18 13:05:00",
      open: 185.875,
      low: 185.78,
      high: 185.875,
      close: 185.822,
      volume: 862
    },
    {
      date: "2021-10-18 13:04:00",
      open: 185.66,
      low: 185.66,
      high: 185.85,
      close: 185.8,
      volume: 1530
    },
    {
      date: "2021-10-18 13:03:00",
      open: 185.58,
      low: 185.4921,
      high: 185.6599,
      close: 185.6095,
      volume: 1168
    },
    {
      date: "2021-10-18 13:02:00",
      open: 185.63,
      low: 185.51,
      high: 185.64,
      close: 185.51,
      volume: 1072
    },
    {
      date: "2021-10-18 13:00:00",
      open: 185.1495,
      low: 185.1495,
      high: 185.42,
      close: 185.4062,
      volume: 2175
    },
    {
      date: "2021-10-18 12:59:00",
      open: 185.0368,
      low: 185.0368,
      high: 185.235,
      close: 185.15,
      volume: 1547
    },
    {
      date: "2021-10-18 12:58:00",
      open: 185.4,
      low: 184.934,
      high: 185.4,
      close: 184.934,
      volume: 9166
    },
    {
      date: "2021-10-18 12:56:00",
      open: 185.53,
      low: 185.35,
      high: 185.5964,
      close: 185.5964,
      volume: 1947
    },
    {
      date: "2021-10-18 12:55:00",
      open: 185.75,
      low: 185.315,
      high: 185.75,
      close: 185.315,
      volume: 7623
    },
    {
      date: "2021-10-18 12:54:00",
      open: 185.85,
      low: 185.78,
      high: 185.85,
      close: 185.79,
      volume: 1297
    },
    {
      date: "2021-10-18 12:53:00",
      open: 185.96,
      low: 185.8671,
      high: 185.96,
      close: 185.8671,
      volume: 830
    },
    {
      date: "2021-10-18 12:52:00",
      open: 185.96,
      low: 185.96,
      high: 185.98,
      close: 185.98,
      volume: 530
    },
    {
      date: "2021-10-18 12:51:00",
      open: 185.8913,
      low: 185.89,
      high: 185.8913,
      close: 185.89,
      volume: 1157
    },
    {
      date: "2021-10-18 12:50:00",
      open: 186.0,
      low: 185.8,
      high: 186.04,
      close: 185.8599,
      volume: 1573
    },
    {
      date: "2021-10-18 12:49:00",
      open: 185.8197,
      low: 185.8197,
      high: 185.97,
      close: 185.97,
      volume: 653
    },
    {
      date: "2021-10-18 12:47:00",
      open: 185.86,
      low: 185.86,
      high: 186.1846,
      close: 186.04,
      volume: 1819
    },
    {
      date: "2021-10-18 12:46:00",
      open: 185.78,
      low: 185.78,
      high: 185.92,
      close: 185.82,
      volume: 2725
    },
    {
      date: "2021-10-18 12:45:00",
      open: 185.78,
      low: 185.7,
      high: 185.79,
      close: 185.79,
      volume: 3554
    },
    {
      date: "2021-10-18 12:44:00",
      open: 185.92,
      low: 185.65,
      high: 185.92,
      close: 185.77,
      volume: 1322
    },
    {
      date: "2021-10-18 12:43:00",
      open: 185.7526,
      low: 185.614,
      high: 185.89,
      close: 185.77,
      volume: 2375
    },
    {
      date: "2021-10-18 12:42:00",
      open: 185.755,
      low: 185.755,
      high: 185.755,
      close: 185.755,
      volume: 830
    },
    {
      date: "2021-10-18 12:41:00",
      open: 185.68,
      low: 185.5772,
      high: 185.927,
      close: 185.927,
      volume: 2123
    },
    {
      date: "2021-10-18 12:40:00",
      open: 185.8,
      low: 185.5101,
      high: 185.8,
      close: 185.5101,
      volume: 4588
    },
    {
      date: "2021-10-18 12:39:00",
      open: 185.89,
      low: 185.89,
      high: 186.01,
      close: 185.99,
      volume: 1256
    },
    {
      date: "2021-10-18 12:38:00",
      open: 185.93,
      low: 185.8768,
      high: 185.94,
      close: 185.92,
      volume: 1170
    },
    {
      date: "2021-10-18 12:37:00",
      open: 186.16,
      low: 186.01,
      high: 186.16,
      close: 186.02,
      volume: 1366
    },
    {
      date: "2021-10-18 12:36:00",
      open: 185.93,
      low: 185.93,
      high: 186.2468,
      close: 186.0873,
      volume: 2450
    },
    {
      date: "2021-10-18 12:35:00",
      open: 186.0015,
      low: 185.89,
      high: 186.1999,
      close: 186.0675,
      volume: 3972
    },
    {
      date: "2021-10-18 12:34:00",
      open: 186.2099,
      low: 186.1,
      high: 186.2099,
      close: 186.1,
      volume: 672
    },
    {
      date: "2021-10-18 12:33:00",
      open: 186.12,
      low: 186.12,
      high: 186.13,
      close: 186.13,
      volume: 724
    },
    {
      date: "2021-10-18 12:32:00",
      open: 186.3763,
      low: 186.16,
      high: 186.3763,
      close: 186.25,
      volume: 1887
    },
    {
      date: "2021-10-18 12:31:00",
      open: 185.69,
      low: 185.43,
      high: 185.98,
      close: 185.98,
      volume: 4799
    },
    {
      date: "2021-10-18 12:30:00",
      open: 186.0209,
      low: 185.67,
      high: 186.2343,
      close: 185.6887,
      volume: 7674
    },
    {
      date: "2021-10-18 12:29:00",
      open: 186.1431,
      low: 186.1431,
      high: 186.2,
      close: 186.2,
      volume: 955
    },
    {
      date: "2021-10-18 12:28:00",
      open: 186.05,
      low: 186.05,
      high: 186.05,
      close: 186.05,
      volume: 537
    },
    {
      date: "2021-10-18 12:27:00",
      open: 185.9,
      low: 185.81,
      high: 186.2299,
      close: 185.93,
      volume: 3593
    },
    {
      date: "2021-10-18 12:26:00",
      open: 186.01,
      low: 185.83,
      high: 186.13,
      close: 185.94,
      volume: 2649
    },
    {
      date: "2021-10-18 12:25:00",
      open: 186.56,
      low: 185.8,
      high: 186.56,
      close: 186.06,
      volume: 8901
    },
    {
      date: "2021-10-18 12:24:00",
      open: 186.56,
      low: 186.5211,
      high: 186.605,
      close: 186.5211,
      volume: 1841
    },
    {
      date: "2021-10-18 12:23:00",
      open: 186.64,
      low: 186.5925,
      high: 186.64,
      close: 186.5925,
      volume: 1206
    },
    {
      date: "2021-10-18 12:22:00",
      open: 186.7,
      low: 186.5,
      high: 186.7841,
      close: 186.7841,
      volume: 4794
    },
    {
      date: "2021-10-18 12:21:00",
      open: 186.6,
      low: 186.6,
      high: 186.6,
      close: 186.6,
      volume: 662
    },
    {
      date: "2021-10-18 12:20:00",
      open: 186.59,
      low: 186.49,
      high: 186.65,
      close: 186.56,
      volume: 2839
    },
    {
      date: "2021-10-18 12:19:00",
      open: 186.785,
      low: 186.6,
      high: 186.785,
      close: 186.6,
      volume: 2312
    },
    {
      date: "2021-10-18 12:18:00",
      open: 186.81,
      low: 186.785,
      high: 186.98,
      close: 186.93,
      volume: 1669
    },
    {
      date: "2021-10-18 12:17:00",
      open: 186.7,
      low: 186.7,
      high: 186.7,
      close: 186.7,
      volume: 1174
    },
    {
      date: "2021-10-18 12:16:00",
      open: 186.6201,
      low: 186.6201,
      high: 186.74,
      close: 186.74,
      volume: 1103
    },
    {
      date: "2021-10-18 12:15:00",
      open: 186.81,
      low: 186.7305,
      high: 186.81,
      close: 186.7305,
      volume: 2101
    },
    {
      date: "2021-10-18 12:14:00",
      open: 187.16,
      low: 187.16,
      high: 187.16,
      close: 187.16,
      volume: 675
    },
    {
      date: "2021-10-18 12:13:00",
      open: 187.14,
      low: 186.975,
      high: 187.14,
      close: 186.975,
      volume: 2295
    },
    {
      date: "2021-10-18 12:12:00",
      open: 186.96,
      low: 186.93,
      high: 187.1603,
      close: 187.1603,
      volume: 3528
    },
    {
      date: "2021-10-18 12:11:00",
      open: 187.001,
      low: 186.74,
      high: 187.001,
      close: 186.87,
      volume: 6131
    },
    {
      date: "2021-10-18 12:10:00",
      open: 187.17,
      low: 187.0201,
      high: 187.17,
      close: 187.0201,
      volume: 1974
    },
    {
      date: "2021-10-18 12:09:00",
      open: 187.13,
      low: 187.13,
      high: 187.1929,
      close: 187.1929,
      volume: 1182
    },
    {
      date: "2021-10-18 12:08:00",
      open: 187.22,
      low: 187.06,
      high: 187.23,
      close: 187.1366,
      volume: 2207
    },
    {
      date: "2021-10-18 12:07:00",
      open: 187.29,
      low: 187.23,
      high: 187.43,
      close: 187.23,
      volume: 2141
    },
    {
      date: "2021-10-18 12:06:00",
      open: 187.11,
      low: 187.11,
      high: 187.35,
      close: 187.2302,
      volume: 1962
    },
    {
      date: "2021-10-18 12:05:00",
      open: 187.3602,
      low: 187.3025,
      high: 187.43,
      close: 187.3025,
      volume: 2307
    },
    {
      date: "2021-10-18 12:04:00",
      open: 187.42,
      low: 187.22,
      high: 187.43,
      close: 187.22,
      volume: 2664
    },
    {
      date: "2021-10-18 12:03:00",
      open: 187.57,
      low: 187.151,
      high: 187.59,
      close: 187.4,
      volume: 2684
    },
    {
      date: "2021-10-18 12:02:00",
      open: 187.7099,
      low: 187.55,
      high: 187.7966,
      close: 187.55,
      volume: 1843
    },
    {
      date: "2021-10-18 12:01:00",
      open: 187.81,
      low: 187.5,
      high: 187.9,
      close: 187.555,
      volume: 4125
    },
    {
      date: "2021-10-18 12:00:00",
      open: 187.74,
      low: 187.67,
      high: 187.84,
      close: 187.84,
      volume: 1508
    },
    {
      date: "2021-10-18 11:59:00",
      open: 187.74,
      low: 187.67,
      high: 187.8,
      close: 187.67,
      volume: 1073
    },
    {
      date: "2021-10-18 11:58:00",
      open: 187.9808,
      low: 187.67,
      high: 188.0,
      close: 187.79,
      volume: 3481
    },
    {
      date: "2021-10-18 11:57:00",
      open: 187.6801,
      low: 187.647,
      high: 187.9999,
      close: 187.7221,
      volume: 3690
    },
    {
      date: "2021-10-18 11:56:00",
      open: 188.2537,
      low: 187.84,
      high: 188.2537,
      close: 187.84,
      volume: 6536
    },
    {
      date: "2021-10-18 11:55:00",
      open: 188.36,
      low: 188.071,
      high: 188.5192,
      close: 188.25,
      volume: 3580
    },
    {
      date: "2021-10-18 11:54:00",
      open: 188.625,
      low: 188.41,
      high: 188.65,
      close: 188.484,
      volume: 3571
    },
    {
      date: "2021-10-18 11:53:00",
      open: 188.18,
      low: 188.18,
      high: 188.69,
      close: 188.69,
      volume: 8842
    },
    {
      date: "2021-10-18 11:52:00",
      open: 188.0721,
      low: 187.63,
      high: 188.2299,
      close: 188.1,
      volume: 9464
    },
    {
      date: "2021-10-18 11:51:00",
      open: 188.5,
      low: 188.11,
      high: 188.61,
      close: 188.11,
      volume: 4410
    },
    {
      date: "2021-10-18 11:50:00",
      open: 188.73,
      low: 188.31,
      high: 188.8499,
      close: 188.44,
      volume: 7083
    },
    {
      date: "2021-10-18 11:49:00",
      open: 188.58,
      low: 188.38,
      high: 188.85,
      close: 188.8499,
      volume: 5804
    },
    {
      date: "2021-10-18 11:48:00",
      open: 188.765,
      low: 188.4801,
      high: 188.8299,
      close: 188.6366,
      volume: 6099
    },
    {
      date: "2021-10-18 11:47:00",
      open: 189.03,
      low: 188.56,
      high: 189.41,
      close: 188.675,
      volume: 20502
    },
    {
      date: "2021-10-18 11:46:00",
      open: 188.66,
      low: 188.45,
      high: 189.87,
      close: 189.24,
      volume: 31924
    },
    {
      date: "2021-10-18 11:45:00",
      open: 188.0789,
      low: 187.94,
      high: 188.71,
      close: 188.65,
      volume: 7459
    },
    {
      date: "2021-10-18 11:44:00",
      open: 188.2,
      low: 187.7404,
      high: 188.2,
      close: 187.944,
      volume: 7773
    },
    {
      date: "2021-10-18 11:43:00",
      open: 186.95,
      low: 186.92,
      high: 188.2,
      close: 187.9,
      volume: 28518
    },
    {
      date: "2021-10-18 11:42:00",
      open: 186.675,
      low: 186.5907,
      high: 186.95,
      close: 186.93,
      volume: 2326
    },
    {
      date: "2021-10-18 11:41:00",
      open: 186.67,
      low: 186.6,
      high: 186.78,
      close: 186.78,
      volume: 1559
    },
    {
      date: "2021-10-18 11:40:00",
      open: 186.715,
      low: 186.61,
      high: 186.715,
      close: 186.61,
      volume: 792
    },
    {
      date: "2021-10-18 11:39:00",
      open: 186.84,
      low: 186.5395,
      high: 186.9,
      close: 186.5395,
      volume: 1854
    },
    {
      date: "2021-10-18 11:38:00",
      open: 186.68,
      low: 186.58,
      high: 186.9,
      close: 186.8329,
      volume: 3057
    },
    {
      date: "2021-10-18 11:37:00",
      open: 186.7,
      low: 186.7,
      high: 186.7,
      close: 186.7,
      volume: 680
    },
    {
      date: "2021-10-18 11:36:00",
      open: 186.4352,
      low: 186.4352,
      high: 186.7799,
      close: 186.7616,
      volume: 2658
    },
    {
      date: "2021-10-18 11:35:00",
      open: 186.87,
      low: 186.54,
      high: 186.87,
      close: 186.54,
      volume: 3338
    },
    {
      date: "2021-10-18 11:34:00",
      open: 186.7,
      low: 186.5201,
      high: 186.9205,
      close: 186.89,
      volume: 4077
    },
    {
      date: "2021-10-18 11:33:00",
      open: 186.18,
      low: 186.18,
      high: 186.8775,
      close: 186.8181,
      volume: 10341
    },
    {
      date: "2021-10-18 11:32:00",
      open: 185.94,
      low: 185.94,
      high: 186.2,
      close: 186.198,
      volume: 3507
    },
    {
      date: "2021-10-18 11:31:00",
      open: 186.2,
      low: 185.9237,
      high: 186.2,
      close: 185.9237,
      volume: 2084
    },
    {
      date: "2021-10-18 11:30:00",
      open: 185.92,
      low: 185.7,
      high: 186.15,
      close: 186.15,
      volume: 1934
    },
    {
      date: "2021-10-18 11:28:00",
      open: 185.975,
      low: 185.975,
      high: 185.975,
      close: 185.975,
      volume: 327
    },
    {
      date: "2021-10-18 11:27:00",
      open: 186.17,
      low: 185.81,
      high: 186.198,
      close: 185.81,
      volume: 4265
    },
    {
      date: "2021-10-18 11:26:00",
      open: 185.74,
      low: 185.74,
      high: 186.2,
      close: 186.14,
      volume: 6066
    },
    {
      date: "2021-10-18 11:25:00",
      open: 185.7655,
      low: 185.51,
      high: 185.77,
      close: 185.74,
      volume: 3144
    },
    {
      date: "2021-10-18 11:24:00",
      open: 185.77,
      low: 185.77,
      high: 185.77,
      close: 185.77,
      volume: 815
    },
    {
      date: "2021-10-18 11:23:00",
      open: 186.0,
      low: 185.74,
      high: 186.0033,
      close: 185.7902,
      volume: 4318
    },
    {
      date: "2021-10-18 11:22:00",
      open: 185.86,
      low: 185.7856,
      high: 186.0,
      close: 186.0,
      volume: 4705
    },
    {
      date: "2021-10-18 11:21:00",
      open: 185.7,
      low: 185.6,
      high: 185.81,
      close: 185.6,
      volume: 1296
    },
    {
      date: "2021-10-18 11:20:00",
      open: 185.69,
      low: 185.65,
      high: 185.85,
      close: 185.85,
      volume: 1605
    },
    {
      date: "2021-10-18 11:19:00",
      open: 185.61,
      low: 185.61,
      high: 185.9299,
      close: 185.7945,
      volume: 1312
    },
    {
      date: "2021-10-18 11:18:00",
      open: 185.58,
      low: 185.3,
      high: 185.61,
      close: 185.61,
      volume: 9367
    },
    {
      date: "2021-10-18 11:17:00",
      open: 185.63,
      low: 185.51,
      high: 185.63,
      close: 185.51,
      volume: 1190
    },
    {
      date: "2021-10-18 11:16:00",
      open: 185.5402,
      low: 185.5402,
      high: 185.6992,
      close: 185.6992,
      volume: 552
    },
    {
      date: "2021-10-18 11:15:00",
      open: 185.73,
      low: 185.73,
      high: 185.86,
      close: 185.735,
      volume: 1196
    },
    {
      date: "2021-10-18 11:14:00",
      open: 185.59,
      low: 185.59,
      high: 185.62,
      close: 185.62,
      volume: 790
    },
    {
      date: "2021-10-18 11:13:00",
      open: 185.55,
      low: 185.43,
      high: 185.7,
      close: 185.4746,
      volume: 2448
    },
    {
      date: "2021-10-18 11:12:00",
      open: 185.85,
      low: 185.67,
      high: 185.94,
      close: 185.84,
      volume: 2939
    },
    {
      date: "2021-10-18 11:11:00",
      open: 185.9699,
      low: 185.8387,
      high: 185.9699,
      close: 185.8387,
      volume: 1002
    },
    {
      date: "2021-10-18 11:10:00",
      open: 185.7347,
      low: 185.7347,
      high: 186.0,
      close: 185.85,
      volume: 5448
    },
    {
      date: "2021-10-18 11:09:00",
      open: 185.7847,
      low: 185.71,
      high: 186.0,
      close: 185.75,
      volume: 1883
    },
    {
      date: "2021-10-18 11:08:00",
      open: 185.5701,
      low: 185.57,
      high: 186.0,
      close: 185.775,
      volume: 3078
    },
    {
      date: "2021-10-18 11:07:00",
      open: 185.56,
      low: 185.56,
      high: 185.76,
      close: 185.72,
      volume: 1758
    },
    {
      date: "2021-10-18 11:06:00",
      open: 185.54,
      low: 185.44,
      high: 185.56,
      close: 185.56,
      volume: 1947
    },
    {
      date: "2021-10-18 11:05:00",
      open: 185.74,
      low: 185.5,
      high: 185.75,
      close: 185.5,
      volume: 4490
    },
    {
      date: "2021-10-18 11:04:00",
      open: 186.03,
      low: 185.67,
      high: 186.1599,
      close: 185.745,
      volume: 5314
    },
    {
      date: "2021-10-18 11:03:00",
      open: 185.6,
      low: 185.5543,
      high: 186.2,
      close: 186.15,
      volume: 12624
    },
    {
      date: "2021-10-18 11:02:00",
      open: 185.59,
      low: 185.59,
      high: 185.6333,
      close: 185.6333,
      volume: 2703
    },
    {
      date: "2021-10-18 11:01:00",
      open: 185.46,
      low: 185.441,
      high: 185.76,
      close: 185.59,
      volume: 2300
    },
    {
      date: "2021-10-18 11:00:00",
      open: 185.06,
      low: 184.96,
      high: 185.48,
      close: 185.47,
      volume: 3874
    },
    {
      date: "2021-10-18 10:59:00",
      open: 185.16,
      low: 184.7764,
      high: 185.35,
      close: 185.0,
      volume: 2366
    },
    {
      date: "2021-10-18 10:58:00",
      open: 185.27,
      low: 185.07,
      high: 185.27,
      close: 185.23,
      volume: 2125
    },
    {
      date: "2021-10-18 10:57:00",
      open: 184.8,
      low: 184.8,
      high: 185.36,
      close: 185.36,
      volume: 3652
    },
    {
      date: "2021-10-18 10:56:00",
      open: 185.18,
      low: 184.8,
      high: 185.23,
      close: 185.15,
      volume: 9598
    },
    {
      date: "2021-10-18 10:55:00",
      open: 185.52,
      low: 185.05,
      high: 185.69,
      close: 185.05,
      volume: 2503
    },
    {
      date: "2021-10-18 10:54:00",
      open: 185.6718,
      low: 185.6718,
      high: 185.6718,
      close: 185.6718,
      volume: 530
    },
    {
      date: "2021-10-18 10:53:00",
      open: 185.5,
      low: 185.5,
      high: 185.87,
      close: 185.5739,
      volume: 3806
    },
    {
      date: "2021-10-18 10:52:00",
      open: 186.165,
      low: 185.55,
      high: 186.165,
      close: 185.55,
      volume: 3209
    },
    {
      date: "2021-10-18 10:51:00",
      open: 186.01,
      low: 185.994,
      high: 186.3402,
      close: 186.1759,
      volume: 3870
    },
    {
      date: "2021-10-18 10:50:00",
      open: 185.53,
      low: 185.53,
      high: 186.34,
      close: 186.01,
      volume: 3883
    },
    {
      date: "2021-10-18 10:49:00",
      open: 185.53,
      low: 185.53,
      high: 186.01,
      close: 185.7741,
      volume: 6416
    },
    {
      date: "2021-10-18 10:48:00",
      open: 186.09,
      low: 185.36,
      high: 186.09,
      close: 185.525,
      volume: 7237
    },
    {
      date: "2021-10-18 10:47:00",
      open: 186.75,
      low: 186.12,
      high: 186.75,
      close: 186.14,
      volume: 12811
    },
    {
      date: "2021-10-18 10:46:00",
      open: 186.13,
      low: 185.92,
      high: 186.75,
      close: 186.7327,
      volume: 14543
    },
    {
      date: "2021-10-18 10:45:00",
      open: 185.49,
      low: 185.3001,
      high: 186.0,
      close: 185.95,
      volume: 21025
    },
    {
      date: "2021-10-18 10:44:00",
      open: 185.33,
      low: 185.17,
      high: 185.5,
      close: 185.2901,
      volume: 14552
    },
    {
      date: "2021-10-18 10:43:00",
      open: 184.955,
      low: 184.955,
      high: 185.3,
      close: 185.25,
      volume: 6677
    },
    {
      date: "2021-10-18 10:42:00",
      open: 184.8,
      low: 184.7312,
      high: 185.2,
      close: 185.2,
      volume: 10846
    },
    {
      date: "2021-10-18 10:41:00",
      open: 184.6397,
      low: 184.45,
      high: 184.714,
      close: 184.45,
      volume: 3396
    },
    {
      date: "2021-10-18 10:40:00",
      open: 184.62,
      low: 184.51,
      high: 184.908,
      close: 184.6793,
      volume: 5199
    },
    {
      date: "2021-10-18 10:39:00",
      open: 184.6,
      low: 184.6,
      high: 184.75,
      close: 184.75,
      volume: 3407
    },
    {
      date: "2021-10-18 10:38:00",
      open: 184.36,
      low: 184.36,
      high: 184.51,
      close: 184.5,
      volume: 2926
    },
    {
      date: "2021-10-18 10:37:00",
      open: 184.83,
      low: 184.3719,
      high: 184.94,
      close: 184.3719,
      volume: 5401
    },
    {
      date: "2021-10-18 10:36:00",
      open: 184.23,
      low: 184.1501,
      high: 184.67,
      close: 184.625,
      volume: 5085
    },
    {
      date: "2021-10-18 10:35:00",
      open: 183.84,
      low: 183.84,
      high: 184.12,
      close: 184.12,
      volume: 2366
    },
    {
      date: "2021-10-18 10:34:00",
      open: 184.0,
      low: 183.65,
      high: 184.125,
      close: 183.8,
      volume: 2003
    },
    {
      date: "2021-10-18 10:33:00",
      open: 184.68,
      low: 184.02,
      high: 184.81,
      close: 184.085,
      volume: 9360
    },
    {
      date: "2021-10-18 10:32:00",
      open: 184.555,
      low: 184.28,
      high: 184.6,
      close: 184.6,
      volume: 3647
    },
    {
      date: "2021-10-18 10:31:00",
      open: 184.57,
      low: 184.37,
      high: 184.57,
      close: 184.41,
      volume: 1360
    },
    {
      date: "2021-10-18 10:30:00",
      open: 184.42,
      low: 184.42,
      high: 184.625,
      close: 184.47,
      volume: 2989
    },
    {
      date: "2021-10-18 10:29:00",
      open: 184.2,
      low: 184.11,
      high: 184.49,
      close: 184.49,
      volume: 1135
    },
    {
      date: "2021-10-18 10:28:00",
      open: 184.29,
      low: 184.1,
      high: 184.3,
      close: 184.15,
      volume: 3809
    },
    {
      date: "2021-10-18 10:27:00",
      open: 184.37,
      low: 184.09,
      high: 184.37,
      close: 184.185,
      volume: 1174
    },
    {
      date: "2021-10-18 10:26:00",
      open: 184.5,
      low: 184.24,
      high: 184.7268,
      close: 184.2401,
      volume: 3183
    },
    {
      date: "2021-10-18 10:25:00",
      open: 184.25,
      low: 184.25,
      high: 184.64,
      close: 184.64,
      volume: 3657
    },
    {
      date: "2021-10-18 10:24:00",
      open: 184.11,
      low: 184.1,
      high: 184.11,
      close: 184.1,
      volume: 616
    },
    {
      date: "2021-10-18 10:23:00",
      open: 183.9758,
      low: 183.9758,
      high: 184.2,
      close: 184.2,
      volume: 2756
    },
    {
      date: "2021-10-18 10:22:00",
      open: 184.04,
      low: 183.96,
      high: 184.047,
      close: 183.97,
      volume: 1926
    },
    {
      date: "2021-10-18 10:21:00",
      open: 184.26,
      low: 184.26,
      high: 184.48,
      close: 184.3044,
      volume: 2024
    },
    {
      date: "2021-10-18 10:20:00",
      open: 183.88,
      low: 183.88,
      high: 184.16,
      close: 184.16,
      volume: 1721
    },
    {
      date: "2021-10-18 10:19:00",
      open: 183.725,
      low: 183.725,
      high: 184.1,
      close: 184.1,
      volume: 4993
    },
    {
      date: "2021-10-18 10:18:00",
      open: 183.5,
      low: 183.5,
      high: 183.595,
      close: 183.595,
      volume: 625
    },
    {
      date: "2021-10-18 10:17:00",
      open: 183.61,
      low: 183.61,
      high: 183.71,
      close: 183.65,
      volume: 5281
    },
    {
      date: "2021-10-18 10:16:00",
      open: 183.5,
      low: 183.3714,
      high: 183.64,
      close: 183.64,
      volume: 1086
    },
    {
      date: "2021-10-18 10:15:00",
      open: 183.5,
      low: 183.5,
      high: 183.5,
      close: 183.5,
      volume: 1367
    },
    {
      date: "2021-10-18 10:14:00",
      open: 183.64,
      low: 183.3904,
      high: 183.65,
      close: 183.3904,
      volume: 3876
    },
    {
      date: "2021-10-18 10:13:00",
      open: 183.16,
      low: 183.1,
      high: 183.5828,
      close: 183.5828,
      volume: 4528
    },
    {
      date: "2021-10-18 10:12:00",
      open: 183.1027,
      low: 183.1027,
      high: 183.6299,
      close: 183.6299,
      volume: 1419
    },
    {
      date: "2021-10-18 10:11:00",
      open: 183.35,
      low: 183.05,
      high: 183.65,
      close: 183.0896,
      volume: 4902
    },
    {
      date: "2021-10-18 10:10:00",
      open: 183.101,
      low: 183.101,
      high: 183.2325,
      close: 183.2325,
      volume: 1359
    },
    {
      date: "2021-10-18 10:09:00",
      open: 182.9267,
      low: 182.9267,
      high: 183.25,
      close: 183.1596,
      volume: 1991
    },
    {
      date: "2021-10-18 10:08:00",
      open: 183.14,
      low: 182.85,
      high: 183.35,
      close: 183.0799,
      volume: 6705
    },
    {
      date: "2021-10-18 10:07:00",
      open: 183.52,
      low: 183.4499,
      high: 183.52,
      close: 183.4499,
      volume: 871
    },
    {
      date: "2021-10-18 10:06:00",
      open: 183.35,
      low: 183.01,
      high: 183.6469,
      close: 183.01,
      volume: 6149
    },
    {
      date: "2021-10-18 10:05:00",
      open: 183.22,
      low: 183.21,
      high: 183.22,
      close: 183.21,
      volume: 709
    },
    {
      date: "2021-10-18 10:04:00",
      open: 183.21,
      low: 183.21,
      high: 183.73,
      close: 183.215,
      volume: 4715
    },
    {
      date: "2021-10-18 10:03:00",
      open: 183.6,
      low: 183.03,
      high: 183.63,
      close: 183.25,
      volume: 3330
    },
    {
      date: "2021-10-18 10:02:00",
      open: 183.4116,
      low: 183.355,
      high: 183.73,
      close: 183.73,
      volume: 3677
    },
    {
      date: "2021-10-18 10:01:00",
      open: 182.69,
      low: 182.665,
      high: 183.6399,
      close: 183.6399,
      volume: 8918
    },
    {
      date: "2021-10-18 10:00:00",
      open: 183.0,
      low: 182.5453,
      high: 183.0,
      close: 182.68,
      volume: 6823
    },
    {
      date: "2021-10-18 09:59:00",
      open: 183.71,
      low: 182.99,
      high: 183.71,
      close: 182.99,
      volume: 4659
    },
    {
      date: "2021-10-18 09:58:00",
      open: 184.11,
      low: 183.4,
      high: 184.16,
      close: 183.4,
      volume: 3741
    },
    {
      date: "2021-10-18 09:57:00",
      open: 184.37,
      low: 184.15,
      high: 184.67,
      close: 184.3386,
      volume: 4781
    },
    {
      date: "2021-10-18 09:56:00",
      open: 184.08,
      low: 184.08,
      high: 184.4099,
      close: 184.245,
      volume: 1377
    },
    {
      date: "2021-10-18 09:55:00",
      open: 184.3962,
      low: 183.77,
      high: 184.48,
      close: 184.2266,
      volume: 4176
    },
    {
      date: "2021-10-18 09:54:00",
      open: 184.24,
      low: 184.19,
      high: 184.4,
      close: 184.22,
      volume: 3255
    },
    {
      date: "2021-10-18 09:53:00",
      open: 184.4,
      low: 184.0101,
      high: 184.4,
      close: 184.0101,
      volume: 1069
    },
    {
      date: "2021-10-18 09:52:00",
      open: 184.242,
      low: 184.21,
      high: 184.3034,
      close: 184.3034,
      volume: 1597
    },
    {
      date: "2021-10-18 09:51:00",
      open: 184.6,
      low: 183.8,
      high: 184.6,
      close: 184.24,
      volume: 3581
    },
    {
      date: "2021-10-18 09:50:00",
      open: 184.55,
      low: 184.55,
      high: 184.789,
      close: 184.789,
      volume: 1239
    },
    {
      date: "2021-10-18 09:49:00",
      open: 185.0392,
      low: 184.5,
      high: 185.0392,
      close: 184.57,
      volume: 4081
    },
    {
      date: "2021-10-18 09:48:00",
      open: 184.8861,
      low: 184.8458,
      high: 185.3,
      close: 185.0,
      volume: 3537
    },
    {
      date: "2021-10-18 09:47:00",
      open: 184.92,
      low: 184.57,
      high: 184.98,
      close: 184.7983,
      volume: 3702
    },
    {
      date: "2021-10-18 09:46:00",
      open: 184.82,
      low: 184.48,
      high: 184.9,
      close: 184.5,
      volume: 2739
    },
    {
      date: "2021-10-18 09:45:00",
      open: 184.525,
      low: 184.525,
      high: 185.2,
      close: 185.2,
      volume: 2773
    },
    {
      date: "2021-10-18 09:44:00",
      open: 184.2437,
      low: 184.2437,
      high: 185.0,
      close: 184.25,
      volume: 8283
    },
    {
      date: "2021-10-18 09:43:00",
      open: 184.91,
      low: 184.5,
      high: 185.108,
      close: 184.7625,
      volume: 5728
    },
    {
      date: "2021-10-18 09:42:00",
      open: 185.3,
      low: 184.8,
      high: 185.38,
      close: 184.91,
      volume: 2289
    },
    {
      date: "2021-10-18 09:41:00",
      open: 185.21,
      low: 185.0101,
      high: 185.53,
      close: 185.53,
      volume: 4969
    },
    {
      date: "2021-10-18 09:40:00",
      open: 184.935,
      low: 184.51,
      high: 185.43,
      close: 185.25,
      volume: 7490
    },
    {
      date: "2021-10-18 09:39:00",
      open: 184.5,
      low: 184.32,
      high: 185.21,
      close: 184.68,
      volume: 12097
    },
    {
      date: "2021-10-18 09:38:00",
      open: 184.5844,
      low: 184.34,
      high: 184.62,
      close: 184.362,
      volume: 3692
    },
    {
      date: "2021-10-18 09:37:00",
      open: 184.8888,
      low: 184.06,
      high: 184.94,
      close: 184.57,
      volume: 6254
    },
    {
      date: "2021-10-18 09:36:00",
      open: 183.5376,
      low: 183.48,
      high: 184.48,
      close: 184.48,
      volume: 6581
    },
    {
      date: "2021-10-18 09:35:00",
      open: 184.6147,
      low: 183.57,
      high: 184.65,
      close: 183.66,
      volume: 13144
    },
    {
      date: "2021-10-18 09:34:00",
      open: 185.46,
      low: 184.49,
      high: 185.46,
      close: 184.6,
      volume: 12386
    },
    {
      date: "2021-10-18 09:33:00",
      open: 184.83,
      low: 184.83,
      high: 185.5,
      close: 185.5,
      volume: 17973
    },
    {
      date: "2021-10-18 09:32:00",
      open: 183.169,
      low: 182.581,
      high: 184.8,
      close: 184.62,
      volume: 16405
    },
    {
      date: "2021-10-18 09:31:00",
      open: 182.7801,
      low: 182.7801,
      high: 183.56,
      close: 183.01,
      volume: 5389
    },
    {
      date: "2021-10-18 09:30:00",
      open: 182.94,
      low: 182.71,
      high: 183.06,
      close: 182.915,
      volume: 22275
    }
  ];
  
  const rotateArray2 = function (nums, k) {
    // reverse helper function
    function reverse(arr, start, end) {
      while (start < end) {
        [arr[start], arr[end]] = [arr[end], arr[start]];
        start++;
        end--;
      }
    }
  
    k %= nums.length;
  
    reverse(nums, 0, nums.length - 1);
    reverse(nums, 0, k - 1);
    reverse(nums, k, nums.length - 1);
  
    return nums;
  };
  
  export let initialData = rotateArray2(oginitialData);
  